import { SET_TOAST_CONFIG } from '../constants';

const setToastConfig = (value) => ({
  type: SET_TOAST_CONFIG,
  payload: {
    value,
  },
});

export default setToastConfig;
