import { SET_ACCESSORIES_CAR_DETAILS } from '../constants';
import StorageBrowser from '../_services/storageBrowser';

const setAccessoriesCar = (value) => {
  try {
    const storageBrowser = new StorageBrowser();
    storageBrowser.setStorageLs('accessoriesCarDetail', value);
  } catch (e) {
    console.error(e);
  }

  return {
    type: SET_ACCESSORIES_CAR_DETAILS,
    payload: {
      value,
    },
  };
};

export default setAccessoriesCar;
