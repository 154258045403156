import { SET_SEGMENT_DETAILS_PAGE } from '../constants';

const segmentDetailsPage = (state = {}, action) => {
  switch (action.type) {
    case SET_SEGMENT_DETAILS_PAGE:
      return action.payload.value;
    default:
      return state;
  }
};


export default segmentDetailsPage;
