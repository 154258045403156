import { SET_ACCESSORIES_BANNER } from '../constants';

function accessoriesBanner(state = [], action) {
  switch (action.type) {
    case SET_ACCESSORIES_BANNER:
      return action.payload.value;
    default:
      return state;
  }
}

export default accessoriesBanner;
