import { SET_PREFFERED_CITY } from '../constants';
import * as events from '../gtm/eventList';
import { isMalaysiaRequest } from '../utilities';

export default function city(state = null, action) {
  switch (action.type) {
    case SET_PREFFERED_CITY: {
      if (typeof window !== 'undefined' && window.gtag) {
        window.gtag('event', events.select_city, {
          city_name: action.payload.value.name,
          country: isMalaysiaRequest() ? 'MS' : 'IN'
        });
      }
      return action.payload.value;
    }
    default:
      return state;
  }
}
