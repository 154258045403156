import StorageBrowser from './storageBrowser';
import storeExport from '../clientStore';
import { localStorageKeys } from '../constants';
import { changeAccessToken } from '../actions/user';
import { sha256 } from 'js-sha256';

class TokenHeader {
  getToken = () => {
    const { store } = storeExport;
    const storageBrowser = new StorageBrowser();
    const currentAppState = store.getState();

    // Get token from LS and store and sync both
    if (storageBrowser.isSupportLS()) {
      let tokenFromLS = null;
      let tokenFromLsForTrackingModule = null;
      try {
        tokenFromLS = storageBrowser.getStorageLS(localStorageKeys.AUTH_TOKEN);
        tokenFromLsForTrackingModule = storageBrowser.getStorageLSnonJson(localStorageKeys.accessToken);
      } catch (e) {}
      let tokenFromAppStore = null;
      try {
        tokenFromAppStore = currentAppState.user.token;
      } catch (e) {}
      if (tokenFromLS && !tokenFromAppStore) {
        store.dispatch(changeAccessToken(tokenFromLS));
      }

      if(tokenFromLS && !tokenFromLsForTrackingModule){
        storageBrowser.setStorageLsNonJson(
          localStorageKeys.accessToken,
          tokenFromLS,
        );
      }
      if(!tokenFromLS && tokenFromLsForTrackingModule){
        storageBrowser.setStorageLs(
          localStorageKeys.AUTH_TOKEN,
          tokenFromLsForTrackingModule,
        );
      }

      if ((tokenFromAppStore && !tokenFromLS) || (tokenFromAppStore && !tokenFromLsForTrackingModule)) {
        storageBrowser.setStorageLs(
          localStorageKeys.AUTH_TOKEN,
          tokenFromAppStore,
        );
        storageBrowser.setStorageLsNonJson(
          localStorageKeys.accessToken,
          tokenFromAppStore,
        );
      }

      if (!tokenFromLS && !tokenFromAppStore) {
        console.error('User seems to be logged out.');
      }
      return tokenFromLS;
    }

    let tokenFromAppStore = null;

    try {
      tokenFromAppStore = currentAppState.user.token;
    } catch (e) {}

    return tokenFromAppStore;
  };

  get_token_headers() {
    const token = this.getToken();
    let headers = {};
    if (token) {
      headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
    } else {
      headers = {
        'Content-Type': 'application/json',
      };
    }
    return headers;
  }

  get_token_extra_headers(number, randomNumber) {
    const token = this.getToken();
    let headers = {};

    const hash_list = ['Marlboro9876548053', number, randomNumber];
    const hash_str = hash_list.join('#');
    // const sha256 = new Hashes.SHA256();
    const calculated_hash = `${sha256(hash_str)}`;

    // console.log("hash_list",hash_list,"hash_str",hash_str,"calculated_hash",calculated_hash);

    if (token) {
      headers = {
        'Content-Type': 'application/json',
        'Authorization': calculated_hash,
      };
    } else {
      headers = {
        'Content-Type': 'application/json',
        'Authorization': calculated_hash
      };
    }
    return headers;
  }
}

export default TokenHeader;
