import { SET_HOT_CATEGORIES_LIST, SET_STAR_PRODUCTS_LIST } from '../constants';

export const starProductsList = (state = [], action) => {
  switch (action.type) {
    case SET_STAR_PRODUCTS_LIST:
      return action.payload.value;
    default:
      return state;
  }
};

export const hotCategoriesList = (state = [], action) => {
  switch (action.type) {
    case SET_HOT_CATEGORIES_LIST:
      return action.payload.value;
    default:
      return state;
  }
};
