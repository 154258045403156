import { SET_PRICE_LIST_DATA } from '../constants';

export default function priceListData(state = null, action) {
  switch (action.type) {
    case SET_PRICE_LIST_DATA:
      return action.payload.value;
    default:
      return state;
  }
}
