import { SET_PREFFERED_CITY } from '../constants';

export function setPrefferedCity(value) {
  return {
    type: SET_PREFFERED_CITY,
    payload: {
      value,
    },
  };
}
