import HttpHeader from '../../httpHeader';
import Global from '../../../global';
import { Http } from '../../../modules/http';

export const getSegmentsList = () => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}segments/`;
  return HttpHandler.get(url, headers);
};

export const getCategoriesList = (segmentSlug) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}categories/?segment_slug=${segmentSlug}`;
  return HttpHandler.get(url, headers);
};

export const getSegmentDetails = (segmentSlug, pageFlag, categorySlug) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}segment-page/?segment_slug=${segmentSlug}&is_category_page=${pageFlag}${categorySlug ? `&category_slug=${categorySlug}` : ''}`;
  return HttpHandler.get(url, headers);
};

export const getNewHomePageDetails = () => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}new-home-page-data/`;
  return HttpHandler.get(url, headers);
};

export const getNewHomePageDetailsV2 = () => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}v2/new-home-page-data/`;
  return HttpHandler.get(url, headers);
};

export const getNewSegmentDetails = (slug, carId, year) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}new-segment-page/?slug=${slug}&car_id=${carId}&car_year=${year}`;
  return HttpHandler.get(url, headers);
};

export const getNewCatgeoryDetails = (slug, segmentSlug, carId, year) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}new-category-page/?segment_slug=${segmentSlug}&slug=${slug}&car_id=${carId}&car_year=${year}`;
  return HttpHandler.get(url, headers);
};

export const getCategoryProducts = (categorySlug, carParam, sparam, fparam, page, prodCount, segmentSlug, brandParam, carBrandSlug) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}products/${categorySlug ? `?category_slug=${categorySlug}` : '?'}${segmentSlug ? `&segment_slug=${segmentSlug}` : ''}${carParam || ''}${sparam || ''}${fparam || ''}${page || ''}${prodCount || ''}${brandParam ? `&brand_slug=${brandParam}` : ''}${carBrandSlug ? `&car_brand_slug=${carBrandSlug}` : ''}`;
  return HttpHandler.get(url, headers);
};

// export const getNewlyLaunchedProducts = (categorySlug, carParam, prodCount, segmentSlug, brandParam, carBrandSlug) => {
//   const Headers = new HttpHeader();
//   const ApiManager = new Global();
//   const HttpHandler = new Http();

//   const headers = Headers.get_token_headers();
//   const url = `${ApiManager.urls.accessories_url}newly-launched-products/${categorySlug ? `?category_slug=${categorySlug}` : '?'}${segmentSlug ? `&segment_slug=${segmentSlug}` : ''}${carParam || ''}${prodCount || ''}${brandParam ? `&brand_slug=${brandParam}` : ''}${carBrandSlug ? `&car_brand_slug=${carBrandSlug}` : ''}`;
//   return HttpHandler.get(url, headers);
// };

export const getStarProducts = () => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}products/?is_star_product=1`;
  return HttpHandler.get(url, headers);
};

export const getHotCategories = () => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}categories/?hot_category=1`;
  return HttpHandler.get(url, headers);
};

export const getHotProducts = (categorySlug) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}products/?category_slug=${categorySlug}&is_hot=1`;
  return HttpHandler.get(url, headers);
};

export const getFilters = (categorySlug, carParam, segmentSlug, brandParam, carBrandSlug) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}filters/?${categorySlug ? `category_slug=${categorySlug}` : ''}${segmentSlug ? `&segment_slug=${segmentSlug}` : ''}${carParam || ''}${brandParam ? `&brand_slug=${brandParam}` : ''}${carBrandSlug ? `&car_brand_slug=${carBrandSlug}` : ''}`;
  return HttpHandler.get(url, headers);
};

export const getFiltersV2 = (categorySlug, carParam, segmentSlug, brandParam, carBrandSlug, fparam) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}dynamic-filters/?${categorySlug ? `category_slug=${categorySlug}` : ''}${segmentSlug ? `&segment_slug=${segmentSlug}` : ''}${carParam || ''}${brandParam ? `&brand_slug=${brandParam}` : ''}${carBrandSlug ? `&car_brand_slug=${carBrandSlug}` : ''}${fparam || ''}`;
  return HttpHandler.get(url, headers);
};

export const getTrendingProductsForCar = (carParam, categorySlug, segmentSlug) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}products/?is_trending=1${carParam || ''}${categorySlug ? `&category_slug=${categorySlug}` : ''}${segmentSlug ? `&segment_slug=${segmentSlug}` : ''}`;
  return HttpHandler.get(url, headers);
};

export const getCarAccessoriesInfo = (carParam, categorySlug, segmentSlug) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}car-accessory-info/?${categorySlug ? `category_slug=${categorySlug}` : ''}${carParam || ''}${segmentSlug ? `&segment_slug=${segmentSlug}` : ''}`;
  return HttpHandler.get(url, headers);
};

export const getAccessoriesCarMakeModelData = () => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}acc-car-data/`;
  return HttpHandler.get(url, headers);
};

export const getCategoryBanners = (categorySlug) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}categories-banner/?category_slug=${categorySlug}`;
  return HttpHandler.get(url, headers);
};

export const getPopularCategories = (segmentSlug) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}popular-cateogries/?segment_slug=${segmentSlug}`;
  return HttpHandler.get(url, headers);
};

export const getHomePageInfo = () => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}new-home-page-info/`;
  return HttpHandler.get(url, headers);
};

export const getBrandPageInfo = (brand) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}new-brand-page/?brand_slug=${brand}`;
  return HttpHandler.get(url, headers);
};

export const getCarPageInfo = (slug) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}page-data-carbrand/?slug=${slug}`;
  return HttpHandler.get(url, headers);
};

export const getAllProducts = (sparam, fparam, page, prodCount) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}products-under/?${sparam || ''}${fparam || ''}${page || ''}${prodCount || ''}`;
  return HttpHandler.get(url, headers);
};

export const getAllProductsFilters = (filterParam) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}filter-under/?${filterParam || ''}`;
  return HttpHandler.get(url, headers);
};

export const getAllAccessoriesBlogs = (pageCount, pageno) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.api_get}v1/oauth/customer/get-app-blog?&per_page=${pageCount || 50}&page=${pageno || 1}&channel=accessories`;
  return HttpHandler.get(url, headers);
};

export const getQuickFilter = (brand, category) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}quick-filters-values/?${brand ? `brand_slug=${brand}` : ''}${category ? `&category_slug=${category}` : ''}`;
  return HttpHandler.get(url, headers);
};

export const getProductsFromQuickFilter = (param) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}quick-filters/?${param}`;
  return HttpHandler.get(url, headers);
};

export const getAccExportPageData = () => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}export-pages/`;
  return HttpHandler.get(url, headers);
};

export const submitExportPageForm = (data) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  delete headers['Content-Type'];
  const url = `${ApiManager.urls.accessories_url}export-form/`;
  return HttpHandler.post(url, data, headers);
};

export const submitSparesExportPageForm = (data) => {
  const Headers = new HttpHeader();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  delete headers['Content-Type'];
  const url = 'https://comms.gomechanic.app/api/v1/sync/exports-inquiry/';
  return HttpHandler.post(url, data, headers);
};
