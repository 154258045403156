import { SET_LUXURY_BRANDS } from "../constants";

export default function luxuryBrands(state = null, action) {
    switch (action.type) {
      case SET_LUXURY_BRANDS:
        return action.payload.value;
      default:
        return state;
    }
  }
  