import { SET_FLIPKART_SELECTED_SERVICES } from '../constants';

function flipkartSelectedServices(state = [], action) {
    switch (action.type) {
        case SET_FLIPKART_SELECTED_SERVICES:
            return action.payload.value;
        default:
            return state;
    }
}

export default flipkartSelectedServices;
