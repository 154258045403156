import { SET_SELECTED_STORE } from '../constants';

export default function selectedStore(state = null, action) {
  switch (action.type) {
    case SET_SELECTED_STORE:
      return action.payload.value;
    default:
      return state;
  }
}
