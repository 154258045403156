import { SET_MILES_PRODUCT_DATA } from '../constants';


export default function amcMilesData(state = null, action) {
  // console.log(action)
  switch (action.type) {
    case SET_MILES_PRODUCT_DATA: {
      return action.payload.value;
    }
    default:
      return state;
  }
}
