import { SET_ACTIVE_CATEGORY, SET_CATEGORIES } from '../constants';

export const categoryList = (state = null, action) => {
  switch (action.type) {
    case SET_CATEGORIES:
      return action.payload.value;
    default:
      return state;
  }
};

export const activeCategory = (state = null, action) => {
  switch (action.type) {
    case SET_ACTIVE_CATEGORY:
      return action.payload.value;
    default:
      return state;
  }
};
