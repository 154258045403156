import { SET_MARKETING_HEADER_TEXT } from '../constants';

export default function marketingHeaderText(state = null, action) {
  switch (action.type) {
    case SET_MARKETING_HEADER_TEXT:
      return action.payload.value;
    default:
      return state;
  }
}
