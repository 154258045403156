import storeExport from '../clientStore';
import { saveLoggedInUserDetails } from '../actions/user';
import { localStorageKeys } from '../constants';
import StorageBrowser from '../_services/storageBrowser';

export const saveLoginDetails = (dataIncoming) => {
  // console.log('saving login details ', dataIncoming);
  const { store } = storeExport;
  let data = null;
  if (dataIncoming.data) data = dataIncoming.data;
  else data = dataIncoming;
  if (!data.id) return;
  let userDetails = { ...data };
  if (dataIncoming.is_logged_in) userDetails.is_logged_in = true;
  if (data.access_token && data.access_token.token) {
    userDetails = {
      ...userDetails,
      token: data.access_token.token.trim(),
    };
    const storageBrowser = new StorageBrowser();
    storageBrowser.setStorageLs(
      localStorageKeys.AUTH_TOKEN,
      data.access_token.token.trim(),
    );
    if (userDetails.is_logged_in) {
      storageBrowser.setStorageLsNonJson(
        localStorageKeys.accessToken,
        data.access_token.token.trim(),
      );
      storageBrowser.setStorageLsNonJson(
        localStorageKeys.email,
        data.email ? data.email.trim() : '',
      );
      storageBrowser.setStorageLsNonJson(
        localStorageKeys.mobile,
        data.mobile ? data.mobile : '',
      );
      storageBrowser.setStorageLsNonJson(
        localStorageKeys.userName,
        data.name ? data.name.trim() : '',
      );
      storageBrowser.setStorageLsNonJson(
        localStorageKeys.userId,
        data.id,
      );
      storageBrowser.setStorageLsNonJson(
        localStorageKeys.is_country,
        'india',
      );
      storageBrowser.setStorageLsNonJson(
        localStorageKeys.ref_code,
        data.ref_code ? data.ref_code : '',
      );
      storageBrowser.setStorageLsNonJson(
        localStorageKeys.go_app_earning,
        data.go_app_money ? data.go_app_money : '',
      )
    }
  }
  // alert('saving login details ', JSON.stringify(userDetails));
  store.dispatch(saveLoggedInUserDetails(userDetails));
};
