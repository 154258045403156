import { SET_ACTIVE_SERVICE_TYPE } from '../constants';

export default function serviceType(state = null, action) {
  switch (action.type) {
    case SET_ACTIVE_SERVICE_TYPE:
      return action.payload.value;
    default:
      return state;
  }
}
