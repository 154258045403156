import { SET_CART_RESOLVED } from '../constants';

export default function cartResolved(state = null, action) {
  switch (action.type) {
    case SET_CART_RESOLVED:
      return action.payload.value;
    default:
      return state;
  }
}
