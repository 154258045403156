import { HomepageSections } from '../constants';

const scrollListeners = [];
const positionToListen = [];

export const allowedListeners = {
  MOBILE_TOP_BAR: 'MOBILE_TOP_BAR',
  DESKTOP_HOMEPAGE_TOP_BAR: 'DESKTOP_HOMEPAGE_TOP_BAR',
  DESKTOP_HOMEPAGE_MIDDLE_BAR: 'DESKTOP_HOMEPAGE_MIDDLE_BAR',
  SERVICES_TYPE_HORIZONTAL_BAR: 'SERVICES_TYPE_HORIZONTAL_BAR',
};
let earlierWidthTopBar = null;
let earlierWidthMiddleBar = null;

const positionTopBarBeforeFixing = null;
const positionMiddleBarBeforeFixing = null;

const makeFixed = (elementId, width) => {
  const element = document.getElementById(elementId);
  if (element && element.getAttribute('fixed') !== 'true') {
    element.style.position = 'fixed';
    element.style.width = `${width}px`;
    element.style.top = 0;
    element.setAttribute('fixed', true);
  }
};

const makeUnFixed = (elementId) => {
  const element = document.getElementById(elementId);
  if (element && element.getAttribute('fixed') === 'true') {
    element.style.position = 'unset';
    element.style.width = 'unset';
    element.style.top = 'unset';
    element.setAttribute('fixed', false);
  }
};

const rough = () => {
  const currentScrollYPos = window.scrollY;
  const DESKTOP_HOME_TOP_BAR = document.getElementById(
    'DESKTOP_HOMEPAGE_TOP_BAR',
  );

  const DESKTOP_HOMEPAGE_MIDDLE_BAR = document.getElementById(
    'DESKTOP_HOMEPAGE_MIDDLE_BAR',
  );

  if (DESKTOP_HOME_TOP_BAR) {
    earlierWidthTopBar = DESKTOP_HOME_TOP_BAR.offsetWidth;
    if (
      currentScrollYPos > DESKTOP_HOME_TOP_BAR.offsetTop
      && currentScrollYPos < DESKTOP_HOMEPAGE_MIDDLE_BAR.offsetTop
    ) {
      makeFixed('DESKTOP_HOMEPAGE_TOP_BAR', earlierWidthTopBar);
    } else {
      makeUnFixed('DESKTOP_HOMEPAGE_TOP_BAR');
    }
  } else {
    console.log('desktop home top bar not found');
  }

  if (DESKTOP_HOMEPAGE_MIDDLE_BAR) {
    earlierWidthMiddleBar = DESKTOP_HOMEPAGE_MIDDLE_BAR.offsetWidth;
    if (
      currentScrollYPos
      > DESKTOP_HOMEPAGE_MIDDLE_BAR.offsetTop + DESKTOP_HOME_TOP_BAR.offsetTop
    ) {
      if (DESKTOP_HOME_TOP_BAR) {
        makeUnFixed('DESKTOP_HOMEPAGE_TOP_BAR');
      }
      makeFixed('DESKTOP_HOMEPAGE_MIDDLE_BAR', earlierWidthMiddleBar);
    } else {
      if (DESKTOP_HOMEPAGE_MIDDLE_BAR) {
        makeUnFixed('DESKTOP_HOMEPAGE_MIDDLE_BAR');
      }
      makeFixed('DESKTOP_HOMEPAGE_TOP_BAR', earlierWidthTopBar);
    }
  }
};

const toHandle = [
  allowedListeners.DESKTOP_HOMEPAGE_TOP_BAR,
  allowedListeners.DESKTOP_HOMEPAGE_MIDDLE_BAR,
  allowedListeners.SERVICES_TYPE_HORIZONTAL_BAR,
];

const footerId = 'FOOTER';
const sideFloaterId = 'SIDE_FLOATER';

const inPosEleId = (elementId) => `${elementId}_IN_POSITION`;
let nearestToCenter = null;
let nearestToNavigationId = null;
let currentActive = null;
const handleScroll = () => {
  const currentYPos = window.scrollY;

  const footer = document.getElementById(footerId);

  const sideFloater = document.getElementById(sideFloaterId);


  if (footer && sideFloater) {
    if ((footer.offsetTop - (currentYPos + footer.offsetHeight)) < 50) {
      sideFloater.style.height = '100vh';
    } else {
      sideFloater.style.height = '0';
    }
  }

  const allSections = Object.keys(HomepageSections);

  for (let i = 0; i < allSections.length; i++) {
    const element = document.getElementById(allSections[i]);
    if (!element) {
      return;
    }
    const elementPosition = element.offsetTop;
    if (nearestToCenter !== null) {
      if (elementPosition > currentYPos) {
        nearestToCenter = elementPosition;
        nearestToNavigationId = allSections[i];
        break;
      }
    } else {
      nearestToCenter = elementPosition;
      nearestToNavigationId = allSections[i];
      break;
    }
  }
  if (!currentActive || currentActive !== nearestToNavigationId) {
    const elements = document.getElementsByClassName('navigationSelected');
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove('navigationSelected');
    }
    const ele = document.getElementById(`${nearestToNavigationId}_NAVIGATOR`);
    if (ele) {
      ele.classList.add('navigationSelected');
      try {
        ele.scrollIntoViewIfNeeded();
      } catch (e) {}
    }
    // ele.scrollIntoView();
    currentActive = nearestToNavigationId;
  }
};

const handleScrollOld = () => {
  const currentYPos = window.scrollY;

  const allInPositions = toHandle
    .map((elementId) => {
      const elementInPos = document.getElementById(inPosEleId(elementId));
      if (!elementInPos) return null;
      return {
        eleInPos: elementInPos,
        offsetTop: elementInPos.offsetTop,
      };
    })
    .filter((element) => !!element);

  allInPositions.sort((a, b) => a.offsetTop - b.offsetTop);

  let mostLast = null;

  for (let i = 0; i < allInPositions.length; i++) {
    const curEle = allInPositions[i];
    if (currentYPos > curEle.eleInPos.offsetTop) {
      mostLast = i;
    }
  }

  // return;

  if (mostLast === null && allInPositions.length > 0) {
    console.log(
      'unfixing here',
      document.getElementById(
        allInPositions[0].eleInPos.id.replace('_IN_POSITION', ''),
      ).id,
    );
    makeUnFixed(
      document.getElementById(
        allInPositions[0].eleInPos.id.replace('_IN_POSITION', ''),
      ).id,
    );
  }

  if (mostLast !== null) {
    const ElementToMove = document.getElementById(
      allInPositions[mostLast].eleInPos.id.replace('_IN_POSITION', ''),
    );
    makeFixed(ElementToMove.id, allInPositions[mostLast].eleInPos.offsetWidth);

    const toUnfixEle = mostLast !== 0
      ? document.getElementById(
        allInPositions[mostLast - 1].eleInPos.id.replace(
          '_IN_POSITION',
          '',
        ),
      )
      : null;
    if (toUnfixEle) {
      makeUnFixed(toUnfixEle.id);
    }

    const toUnfixEleNext = mostLast !== allInPositions.length - 1
      ? document.getElementById(
        allInPositions[mostLast + 1].eleInPos.id.replace(
          '_IN_POSITION',
          '',
        ),
      )
      : null;
    if (toUnfixEleNext) {
      makeUnFixed(toUnfixEleNext.id);
    }
  }

  // for (let i = 0; i < allInPositions.length; i++) {
  // const curEle = allInPositions[i];
  //
  //
  // if(currentYPos > curEle.eleInPos.offsetTop) {
  //   const ElementToMove = document.getElementById(curEle.eleInPos.id.replace('_IN_POSITION', ''));
  //   makeFixed(ElementToMove.id, curEle.eleInPos.offsetWidth);
  //
  //   const toUnfixEle = (i !== 0) ? document.getElementById(allInPositions[i-1].eleInPos.id.replace('_IN_POSITION', '')) : null;
  //   if(toUnfixEle) {
  //     makeUnFixed(toUnfixEle.id);
  //   }
  // } else {
  //   makeUnFixed(document.getElementById(curEle.eleInPos.id.replace('_IN_POSITION', '')));
  // }
  // }

  // console.log(currentYPos);
  // console.log(
  //   'sorted',
  //   allInPositions.map(ele => ({
  //     id: ele.eleInPos.id,
  //     oTop: ele.eleInPos.offsetTop,
  //   })),
  // );
};

const attachFixedElement = (name, htmlRef, position, callback) => {
  for (let i = 0; i < scrollListeners.length; i++) {
    if (scrollListeners[i].name === name) {
      scrollListeners[i].htmlRef = htmlRef;
      scrollListeners[i].elementPosition = position;
      scrollListeners[i].callback = callback;
      return;
    }
  }
  scrollListeners.push({
    name,
    htmlRef,
    position,
    callback,
  });
  if (!positionToListen.includes(position)) {
    positionToListen.push(position);
  }
};

// const homepageNavigationHandler = () => {
//
// };

export const initiateListener = () => {
  window.removeEventListener('scroll', handleScroll);
  window.addEventListener('scroll', handleScroll, { passive: true });
};
