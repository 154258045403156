import { SET_CAR_MODEL_PAGE } from '../constants';

const carModelPage = (state = {}, action) => {
  switch (action.type) {
    case SET_CAR_MODEL_PAGE:
      return action.payload.value;
    default:
      return state;
  }
};


export default carModelPage;
