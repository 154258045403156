import { SET_HOW_GOMECH_WORKS } from "../constants";

export default function how_gomech_works(state = [], action) {
  switch (action.type) {
    case SET_HOW_GOMECH_WORKS:
      return action.payload.value;
    default:
      return state;
  }
}
