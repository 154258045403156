import { SET_CART_RESOLVED } from '../constants';

export function setCartResolved(value) {
  return {
    type: SET_CART_RESOLVED,
    payload: {
      value,
    },
  };
}
