import { SET_ORDER_DATE_TIME } from '../constants';


export default function orderDateTme(state = null, action) {
  // console.log(action)
  switch (action.type) {
    case SET_ORDER_DATE_TIME: {
      return action.payload.value;
    }
    default:
      return state;
  }
}
