const keyNames = {
  city: 'sitemap_name',
  subCity: 'sitename',
  serviceType: 'slug',
  brand: 'slug_id',
  model: 'slug',
  fuel: 'name',
};

const generate = (
  {
    city, subCity, service, serviceType, brand, model, fuel, currentPath, storeCode, storeAddress,
  },
  forceCityPage = false,
) => {
  // console.log('received', { city, subCity, serviceType, brand, model, fuel });
  if (service && !serviceType) {
    // eslint-disable-next-line no-param-reassign
    serviceType = { ...service, slug: service.service_slug };
  }
  const url = null;

  if (!city) return url;
  if (forceCityPage) return `/${city[keyNames.city]}`;

  if (city && storeCode) {
    if (storeAddress == '') return `/stores/car-service-center-in-address-${city[keyNames.city]}-${storeCode}`;

    return `/stores/car-service-center-in-${storeAddress}`;
  }

  if (city && currentPath && currentPath == 'stores') return `/${city[keyNames.city]}/stores`;

  if (city && !subCity && !serviceType) return `/${city[keyNames.city]}`;

  if (city && subCity && !serviceType) return `/${city[keyNames.city]}/${subCity[keyNames.subCity]}`;

  if (city && serviceType && subCity && !brand) {
    return `/${city[keyNames.city]}/${serviceType[keyNames.serviceType]}-in-${
      subCity[keyNames.subCity]
    }`;
  }

  if (city && serviceType && !brand) return `/${city[keyNames.city]}/${serviceType[keyNames.serviceType]}`;

  if (city && serviceType && subCity && brand && !model) {
    return `/${city[keyNames.city]}/${serviceType[keyNames.serviceType]}-in-${
      subCity[keyNames.subCity]
    }/${brand[keyNames.brand]}`;
  }

  if (city && serviceType && brand && !model) {
    return `/${city[keyNames.city]}/${serviceType[keyNames.serviceType]}/${
      brand[keyNames.brand]
    }`;
  }

  if (city && serviceType && subCity && brand && model && !fuel) {
    return `/${city[keyNames.city]}/${serviceType[keyNames.serviceType]}-in-${
      subCity[keyNames.subCity]
    }/${brand[keyNames.brand]}-${model[keyNames.model]}`;
  }

  if (city && serviceType && brand && model && !fuel) {
    return `/${city[keyNames.city]}/${serviceType[keyNames.serviceType]}/${
      brand[keyNames.brand]
    }-${model[keyNames.model]}`;
  }

  if (city && serviceType && subCity && brand && model && fuel) {
    return `/${city[keyNames.city]}/${serviceType[keyNames.serviceType]}-in-${
      subCity[keyNames.subCity]
    }/${brand[keyNames.brand]}-${model[keyNames.model]}/${fuel[
      keyNames.fuel
    ].toLowerCase()}`;
  }

  if (city && serviceType && brand && model && fuel) {
    return `/${city[keyNames.city]}/${serviceType[keyNames.serviceType]}/${
      brand[keyNames.brand]
    }-${model[keyNames.model]}/${fuel[keyNames.fuel].toLowerCase()}`;
  }
  return url;
};
export default generate;
