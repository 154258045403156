import HttpHeader from '../../httpHeader';
import Global from '../../../global';
import { Http } from '../../../modules/http';

export const addToCartApi = (cartData) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}cart/order-line/`;
  return HttpHandler.post(
    url,
    {
      ...cartData,
    },
    headers,
  );
};

export const deleteToCartApi = (cartData) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}cart/order-line/`;
  return HttpHandler.delete(
    url,
    {
      ...cartData,
    },
    headers,
  );
};

export const getAccesoriesCart = () => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const endpoint = `${ApiManager.urls.accessories_url}cart/`;

  const headers = Headers.get_token_headers();
  return HttpHandler.get(endpoint, headers);
};

export const applyAccessoriesCoupon = (coupon) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const url = `${ApiManager.urls.accessories_url}apply-coupon/`;

  const headers = Headers.get_token_headers();
  return HttpHandler.post(
    url,
    {
      ...{ promo_code: coupon },
    },
    headers,
  );
};

export const removeAccessoriesCoupon = () => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const url = `${ApiManager.urls.accessories_url}apply-coupon/`;

  const headers = Headers.get_token_headers();
  return HttpHandler.delete(
    url,
    {},
    headers,
  );
};

export const getPaymentModeDiscount = (mode) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const url = `${ApiManager.urls.accessories_url}cart/extra-discount/`;

  const headers = Headers.get_token_headers();
  return HttpHandler.post(
    url,
    {
      ...{ payment_mode: mode },
    },
    headers,
  );
};

export const addAccessoriesAddress = (addressData) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const url = `${ApiManager.urls.accessories_url}addresses/`;

  const headers = Headers.get_token_headers();
  return HttpHandler.post(
    url,
    {
      ...addressData,
    },
    headers,
  );
};

export const editUserAddress = (addressData) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}addresses/`;

  return HttpHandler.put(
    url,
    {
      ...addressData,
    },
    headers,
  );
};

export const getDeliveryEstimate = (addressId, isCheckout) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const url = `${ApiManager.urls.accessories_url}estimate-delivery/`;

  const headers = Headers.get_token_headers();
  return HttpHandler.post(
    url,
    {
      address_id: addressId,
      isCheckout: !!isCheckout,
    },
    headers,
  );
};

export const getAccessoriesPaymentOffers = () => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const endpoint = `${ApiManager.urls.accessories_url}v2/cart-coupon/`;

  const headers = Headers.get_token_headers();
  return HttpHandler.get(endpoint, headers);
};

export const createAccessoriesLead = ({ utmSource, carModelId }) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const url = `${ApiManager.urls.accessories_url}create-checkout-lead/`;
  const postData = {};
  if (carModelId) postData.car_id = carModelId;
  if (utmSource) postData.utm_source = utmSource;
  const headers = Headers.get_token_headers();
  return HttpHandler.post(
    url,
    {
      ...postData,
    },
    headers,
  );
};

export const getAccessoriesCartRecomendation = (postData) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const endpoint = `${ApiManager.urls.accessories_url}recommended-products/`;

  const headers = Headers.get_token_headers();
  return HttpHandler.post(
    endpoint,
    {
      ...postData,
    },
    headers,
  );
};

export const getCodPaymentMethodStatus = (postData) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const endpoint = `${ApiManager.urls.accessories_url}show-payment-methods/`;

  const headers = Headers.get_token_headers();
  return HttpHandler.post(
    endpoint,
    {
      ...postData,
    },
    headers,
  );
};

export const getAccGoAppMoney = () => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const endpoint = `${ApiManager.urls.accessories_url}go-app-money/`;

  const headers = Headers.get_token_headers();
  return HttpHandler.get(endpoint, headers);
};

export const applyAccGoAppMoney = () => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const url = `${ApiManager.urls.accessories_url}go-app-money/`;

  const headers = Headers.get_token_headers();
  return HttpHandler.post(
    url,
    {},
    headers,
  );
};

export const removeAccGoAppMoney = () => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const url = `${ApiManager.urls.accessories_url}go-app-money/`;

  const headers = Headers.get_token_headers();
  return HttpHandler.delete(
    url,
    {},
    headers,
  );
};

export const userWhatsAppNotify = (flag) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const url = `${ApiManager.urls.accessories_url}whatsapp-user-auth/`;

  const headers = Headers.get_token_headers();
  return HttpHandler.post(
    url,
    { acc_whatsapp_flag: flag },
    headers,
  );
};

export const getUserGSTInfo = () => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const url = `${ApiManager.urls.accessories_url}user-details/`;

  const headers = Headers.get_token_headers();
  return HttpHandler.get(url, headers);
};

export const addUserGSTNumber = (gstin) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const url = `${ApiManager.urls.accessories_url}user-details/`;

  const headers = Headers.get_token_headers();
  return HttpHandler.put(
    url,
    { acc_gstin: gstin },
    headers,
  );
};

export const verifyGSTApi = (gstin) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const url = `${ApiManager.urls.accessories_url}gst-verification/?acc_gstin=${gstin}`;

  const headers = Headers.get_token_headers();
  return HttpHandler.get(url, headers);
};
