import { SET_REVIEWS_CURRENT_CITY } from '../constants';

export function reviewsCurrentCity(value) {
  return {
    type: SET_REVIEWS_CURRENT_CITY,
    payload: {
      value,
    },
  };
}
