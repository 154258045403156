import { SET_ACCESSORIES_CART_RESOLVED } from '../constants';

export function setAccessoriesCartResolved(value) {
  return {
    type: SET_ACCESSORIES_CART_RESOLVED,
    payload: {
      value,
    },
  };
}
