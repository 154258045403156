import HttpHeader from '../httpHeader';
import { Http } from '../../modules/http';
import Global from '../../global';

export const fetchUserDetails = () => {
  const Headers = new HttpHeader();
  const HttpHandler = new Http();
  const ApiManager = new Global();


  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.api_get}v1/oauth/customer/get-user-details/`;
  return HttpHandler.get(url, headers);
};
