import { SET_POPULAR_SERVICE_V2, SET_CITY_CAROUSELS } from '../constants';

export default function home(state = null, action) {
    switch (action.type) {
      case 'NON_SETTING_KEY':
        return action.payload.value;
      case SET_POPULAR_SERVICE_V2: 
        return {...state, popularServiceV2: action.payload.value};
      case SET_CITY_CAROUSELS: 
        return {...state, cityWiseCarousel: {...(state.home?.cityWiseCarousel ?? {}), ...action.payload.value}};
      default:
        return state;
    }
  }
  