import { combineReducers } from 'redux';
import city from './city';
import cart from './cart';
import cardetails from './cardetails';
import bikedetails from './bikedetails';
import marketingHeaderText from './marketingHeaderText';
import device from './device';
import user from './user';
import brand from './brand';
import serviceType from './serviceType';
import allCities from './allCities';
import allBrands from './allBrands';
import reviewsCurrentCity from './reviewsCurrentCity';
import regions from './regions';
import selectedRegion from './selectedRegion';
import faqs from './faqs';
import blogs from './blogs';
import isUserLoggedIn from './isUserLoggedIn';
import orderDateTime from './orderDateTime';
import coupon from './coupon';
import userSelectedCarId from './userSelectedCarId';
import carForCart from './carForCart';
import cartResolved from './cartResolved';
import popularBrands from './popularBrands';
import luxuryBrands from './luxuryBrands';
import { activeSegment, segmentList } from './segment';
import { activeCategory, categoryList } from './category';
import accessoriesBanner from './accessoriesBanner';
import accCities from './accCities';
import userAccessoriesCart from './userAccessoriesCart';
import lead from './lead';
import storeList from './storeList';
import selectedStore from './selectedStore';
import nearByStore from './nearByStore';
import storeLocation from './storeLocation';
import accessoriesCarDetail from './accessoriesCarDetail';
import accessoriesCarBrand from './accessoriesCarBrand';
import accessoriesVehicleType from './accessoriesVehicleType';
import bikeenquirs from './bikeenquirs';
import footer from './footer';
import policy from './policy';
import home from './home';
import serviceGuide from './serviceGuide';
import { hotCategoriesList, starProductsList } from './accessoriesHome';
import productPanelPage from './productPanelPage';
import productDetailsPage from './productDetailsPage';
import segmentDetailPage from './segmentDetailPage';
import segmentPageDetails from './segmentPageDetails';
import accHomePageDetails from './accHomePageDetails';
import accBrandPageDetails from './accBrandPageDetails';
import categoryPageDetails from './categoryPageDetails';
import carModelPage from './carModelPage';
import carBrandPageData from './carBrandPageData';
import accessoriesCollectionPage from './accessoriesCollectionPage';
import showServicesCollapsed from './showServicesCollapsed';
import accessoriesCartResolved from './accessoriesCartResolved';
import carSelection from './carSelection';
import accessoriesFaq from './accessoriesFaq';
import displayConfigurationReducer from './displayConfigurationReducer';
import displayPossibleSctions from './displayPossibleSections';
import addOnServices from './addOnServices';
import selectedAddressId from './selectedAddressId';
import toast from './toast';
import selectedPaymentMethod from './selectetedPaymentType';
import categoryWiseReviews from './categoryWiseReviews';
import WarrantyCard from './setWarrantyCard';
import workShop from './workShop';
import serviceWiseReview from './serviceWiseReview';
import TimeSelect from './setTimeSelect';
import UserRemarks from './setUserRemarks';
import cartBoosters from './cartBoosters';
import priceListReducer from './priceListReducer';
import currentPageData from './currentPage';
import reviewPageData from './reviewPageData';
import offersPage from './offersPage';
import setMonsoonCatId from './setMonsoonData';
import serviceWarranty from './serviceWarranty';
import milesProductData from './milesProductData';
import serviceBuddyData from './serviceBuddyData';
import seoServiceDetails from './seoServiceDetails';
import amcMilesData from './amcMilesData';
import isAmcUser from './isAmcUser';
import flipkartSelectedServices from './flipkartSelectedServices';
import carPageData from './carPageData';
import brandWiseModels from './brandWiseModals';
import cityWiseRegions from './cityWiseRegions';
import gomech_benefits from './gomech_benefits';
import tableData from './tableData';
import servicesDetails from './servicesDetails';
import how_gomech_works from './how_gomech_works';
import warrantySelectedTab from './warrantySelectedTab';
import warrantyDetails from './warrantyDetails';
import obdDetails from './obdDetails';
import accCollectionDataV2 from './accCollectionDataV2';
import activeCarsList from './activeCarsList';
import accessoriesEnquiryData from './accessoriesEnquiryData';
import lazadaSelectedServices from './lazadaSelectedServices';
import selectedPaymentMethodType from './selectedPaymentMethodType';
import selectedPaymentMethodName from './selectedPaymentMethodName';
import accSearchData from './AccSearchData';
import accBlogs from './accBlogs';

const rootReducer = combineReducers({
  city,
  cart,
  cardetails,
  accessoriesCarDetail,
  accessoriesCarBrand,
  accessoriesVehicleType,
  bikedetails,
  marketingHeaderText,
  device,
  user,
  brand,
  serviceType,
  setMonsoonCatId,
  serviceWarranty,
  milesProductData,
  amcMilesData,
  isAmcUser,
  allCities,
  allBrands,
  popularBrands,
  footer,
  accFooter: (state, action) => state || [],
  policy,
  home,
  serviceGuide,
  luxuryBrands,
  reviewsCurrentCity,
  seoServiceDetails,
  warrantySelectedTab,
  warrantyDetails,
  obdDetails,
  how_gomech_works,
  gomech_benefits,
  // custom_repairs_text: (state, action) => state || null,
  servicesTypesList: (state, action) => state || [],
  categoryView: (state, action) => state || null,
  servicesDetails,
  regions,
  selectedRegion,
  offersPage,
  faqs,
  blogs,
  carSelection,
  isUserLoggedIn,
  orderDateTime,
  coupon,
  bikeenquirs,
  workShop,
  userSelectedCarId,
  carForCart,
  currentPageData,
  reviewPageData,
  cartRules: (state, action) => state || [],
  isPhonePeRequest: (state, action) => state || false,
  isPaytmRequest: (state, action) => state || false,
  currency: (state, action) => state || {
    symbol: '₹',
    text: 'Rs. ',
    isoFormat: 'INR',
  },
  ip: (state, action) => state || '',
  isNigerianRequest: (state, action) => state || false,
  isMalaysiaRequest: (state, action) => state || false,
  isCountry: (state, action) => state || false,
  isLat: (state, action) => state || false,
  isLong: (state, action) => state || false,
  topContent: (state, action) => state || false,
  tableData,
  cartResolved,
  accessoriesCartResolved,
  apiEndpoints: (state, action) => state || [],
  segmentList,
  categoryList,
  accessoriesBanner,
  accCities,
  activeSegment,
  activeCategory,
  userAccessoriesCart,
  lead,
  carouselBanners: (state, action) => state || [],
  cityWiseRegions,
  brandWiseModels, //: (state, action) => state || {},
  accessoriesPageType: (state, action) => state || [],
  isCrawlerRequest: (state, action) => state || false,
  storeList,
  gmbData: (state, action) => state || false,
  storePage: (state, action) => state || false,
  administrativeArea: (state, action) => state || false,
  storeName: (state, action) => state || false,
  selectedStore,
  nearByStore,
  storeLocation,
  starProductsList,
  hotCategoriesList,
  productPanelPage,
  productDetailsPage,
  segmentDetailPage,
  segmentPageDetails,
  accHomePageDetails,
  accBrandPageDetails,
  categoryPageDetails,
  carModelPage,
  carBrandPageData,
  accessoriesCollectionPage,
  showServicesCollapsed,
  initialQueryString: (state, action) => state || '',
  showMap: (state, action) => (state !== undefined ? state : true),
  accessoriesFaq,
  // activeSubcategory,
  displayConfigurations: displayConfigurationReducer,
  displayPossibleSctions,
  addOnServices,
  selectedAddressId,
  toast,
  selectedPaymentMethod,
  categoryWiseReviews,
  servicesDetailsFromServer: (state, action) => state || null,
  serviceWiseReview,
  WarrantyCard,
  TimeSelect,
  UserRemarks,
  cartBoosters,
  priceListData: priceListReducer,
  serviceBuddyData,
  flipkartSelectedServices,
  lazadaSelectedServices,
  carPageData,
  accCollectionDataV2,
  activeCarsList,
  accessoriesEnquiryData,
  accessoriesEnquiryFooterData: (state) => state || {},
  retailAccessoriesPage: (state) => state || null,
  selectedPaymentMethodType,
  selectedPaymentMethodName,
  accSearchData,
  accBlogs,
  isCarWaleUrl: (state, action) => state || false,
  accCategoryWiseAppReviews: (state) => state || null,
  sosFaqs: (state) => state || {},
});

export default rootReducer;
