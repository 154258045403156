import { SET_ACCESSORIES_FAQ } from '../constants';

function accessoriesFaq(state = {}, action) {
  switch (action.type) {
    case SET_ACCESSORIES_FAQ:
      return action.payload.value;
    default:
      return state;
  }
}

export default accessoriesFaq;
