import { SET_SELECTED_REGION } from '../constants';

export default function selectedRegion(state = null, action) {
  switch (action.type) {
    case SET_SELECTED_REGION:
      return action.payload.value;
    default:
      return state;
  }
}
