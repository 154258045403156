import { SET_AMC_USER } from '../constants';

export default function isAmcUser(state = null, action) {
  switch (action.type) {
    case SET_AMC_USER:
      return action.payload.value;
    default:
      return state;
  }
}
