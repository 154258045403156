import { descriptionData, mlyDescriptionData } from './descriptionData';
import history from '../../history';
import { getSitemapMetaDescription } from './directory-metatags';
import { isMalaysiaRequest } from '../../utilities';

export const replaceTemplatesForSeo = (text, replaceObj) => {
  const {
    city, serviceType, brand, model, fuel, subzone, service,
  } = replaceObj;
  const regexCity = /\{\{\$city\}\}/gi;
  const regexServiceType = /\{\{\$service\}\}/gi;
  const regexBrand = /\{\{\$brand\}\}/gi;
  const regexModel = /\{\{\$model\}\}/gi;
  const regexFuel = /\{\{\$fuel\}\}/gi;
  const regexSubZone = /\{\{\$subZone\}\}/gi;
  const regexService = /\{\{\$subService\}\}/gi;
  const regexServiceDetailName = /\{\{\$serviceName\}\}/gi;
  let newText = text;

  const capitalizeStrFirstLetter = (string) => {
    var splitStr = string.replace(/-/g, ' ').toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    return splitStr.join(' '); 
  }

  if (city) newText = newText.replace(regexCity, city.name);
  if (serviceType) newText = newText.replace(regexServiceType, serviceType.name);
  if (brand) newText = newText.replace(regexBrand, brand.name);
  if (model) newText = newText.replace(regexModel, model.name);
  if (fuel) newText = newText.replace(regexFuel, fuel.name);
  if (subzone) newText = newText.replace(regexSubZone, subzone.name);
  if (service) newText = newText.replace(regexService, service.name);
  if (service) newText = newText.replace(regexServiceDetailName, capitalizeStrFirstLetter(service.service_slug));

  return newText;
};

export const newReplaceTemplate = (text, replaceObj) => {
  const {
    city, serviceType, brand, model, fuel, subzone, service,
  } = replaceObj;

  let newText = text;
  const regexCity = /\{\{\$commonCity \?\? \'\'\}\}/gi; // `/${findCity}/g`; // new RegExp(findCity, 'g');
  const regexCity2 = /\{\{\$commonCity \?\? \'City\'\}\}/gi; // `/${findCity2}/g`; // new RegExp(findCity2, 'g');
  const regexCity3 = /\{\{\$metaCity\}\}/gi;
  const regexCity4 = /\{\{\$city\}\}/gi;
  const regexBrand = /\{\{\$urlBrand\}\}/gi;
  const regexcarBrand = /\{\{\$brand\}\}/gi;
  const regexCarModel = /\{\{\$carDetails\}\}/gi;
  const regexModel = /\{\{\$model\}\}/gi;
  const regexFuel = /\{\{\$fuel\}\}/gi;
  const regexcarBrandModelWithCar = /\{\{\$carBrandModelDetailsWithCar\}\}/gi;
  const regexcarBrandModelMlyWithCar = /\{\{\$carBrandModelDetailsMlyWithCar\}\}/gi;
  const regexServiceType = /\{\{\$service\}\}/gi;
  const regexSubZone = /\{\{\$subZone\}\}/gi;
  const regexService = /\{\{\$subService\}\}/gi;
  const regexSubZoneCity = /\{\{\$metaSubZoneCity\}\}/gi;
  const regexServiceDetailName = /\{\{\$serviceName\}\}/gi;

  const capitalizeStrFirstLetter = (string) => {
    var splitStr = string.replace(/-/g, ' ').toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    return splitStr.join(' '); 
  }

  if (
    subzone
    && subzone.name
  ) {
    newText = newText
      .replace(regexCity, subzone.name)
      .replace(regexCity2, subzone.name)
      .replace(regexCity3, subzone.name);
  } else {
    newText = newText
      .replace(regexCity, city.name)
      .replace(regexCity2, city.name)
      .replace(regexCity3, city.name);
  }

  newText = newText.replace(
    regexBrand,
    brand ? brand.name : 'Car',
  );

  newText = newText.replace(
    regexSubZoneCity,
    (subzone && subzone.name) ? `${subzone.name}, ${city.name}` : 
    city ? city.name : 'City',
  );

  newText = newText.replace(
    regexcarBrand,
    brand ? brand.name : 'Car',
  );

  newText = newText.replace(
    regexCarModel,
    model ? model.name : 'Car',
  );

  newText = newText.replace(
    regexFuel,
    fuel ? fuel.name : 'Car',
  );

  newText = newText.replace(
    regexcarBrandModelWithCar,
      fuel ? `${brand.name} ${model.name} ${fuel.name}` :
      model ?  `${brand.name} ${model.name}`:
      brand ? `${brand.name}` : `car`,
  ).trim();

  newText = newText.replace(
    regexcarBrandModelMlyWithCar,
      fuel ? `${brand.name} ${model.name} ${fuel.name}` :
      model ?  `${brand.name} ${model.name}`:
      brand ? `${brand.name}` : `car`,
  ).trim();

  newText = newText.replace(
    regexServiceType,
    (serviceType && serviceType.name) ? serviceType.name : 'Service',
  );

  newText = newText.replace(
    regexService,
    (service && service.name) ? service.name : 'Service',
  );

  newText = newText.replace(
    regexModel,
    model ? model.name : 'Car',
  );

  newText = newText.replace(
    regexSubZone,
    (subzone && subzone.name) ? subzone.name : '',
  );

  newText = newText.replace(
    regexCity4,
    (city && city.name) ? city.name : 'City',
  );

  newText = newText.replace(
    regexServiceDetailName,
    capitalizeStrFirstLetter(service.service_slug)
  );

  return newText;
}

export const replaceTemplates = (text, replaceObj) => {
  const {
    city, serviceType, service, brand, model, fuel, subzone,
  } = replaceObj;
  const regexCity = /<City>/gi;
  const regexCar = /<Car>/gi;
  const regexServiceType = /<ServiceName>/gi;
  const regexService = /<Service>/gi;
  const regexBrand = /<CarBrand>/gi;
  const regexModel = /<CarModel>/gi;
  const regexFuel = /<CarFuel>/gi;
  const regexSubZone = /<SubZone>/gi;
  let newText = text;

  if (city && subzone) {
    newText = newText.replace(regexCity, `${subzone.name}, ${city.name}`);
  } else if (city) newText = newText.replace(regexCity, city.name);

  if (serviceType) {
    newText = newText.replace(regexServiceType,
      (serviceType.seoTitle || serviceType.name));
  }
  if (service) {
    newText = newText.replace(regexService,
      (service.seoTitle || service.name));
  }
  let car = '';
  if (brand) car += brand.name;
  if (model) car += ` ${model.name}`;
  if (fuel) car += ` ${fuel.name}`;
  newText = newText.replace(regexCar, car);
  if (brand) newText = newText.replace(regexBrand, brand.name);
  if (model) newText = newText.replace(regexModel, model.name);
  if (fuel) newText = newText.replace(regexFuel, fuel.name);
  if (subzone) newText = newText.replace(regexSubZone, subzone.name);
  return newText;
};

const descriptionGenerator = (store, preDefinedDescription, forcePreDefinedDescription) => {
  if (!preDefinedDescription) preDefinedDescription = 'GoMechanic';
  if (forcePreDefinedDescription) return preDefinedDescription;
  const descriptionGenerteData = isMalaysiaRequest() ? mlyDescriptionData : descriptionData;
  const {
    city,
    serviceType,
    cardetails,
    selectedRegion,
    selectedStore,
    storeLocation,
    storePage,
    administrativeArea,
    storeName,
    retailAccessoriesPage
  } = store;

  const objForReplacer = {
    city: city || null,
    serviceType: serviceType || null,
    brand: (cardetails && cardetails.brand) ? cardetails.brand : null,
    model: (cardetails && cardetails.model) ? cardetails.model : null,
    fuel: (cardetails && cardetails.fuel) ? cardetails.fuel : null,
    subzone: selectedRegion || null,
  };

  if (history && history.location && history.location.pathname.includes("directory")) {
    const { pathname } = history.location;
    return getSitemapMetaDescription(pathname);
  }

  if(retailAccessoriesPage){
    return `Browse Car Android Screens, Seat Covers, Speakers & more. Get top car accessories brands in ${city?.name}. Free Installation | Easy Return & Replacement`;}

  if (!city) return preDefinedDescription;


  // GMB Store Page
  if (city && storePage) {
    return `List of 100+ Best Car Service Center in ${city.name}, Book your car service today with GoMechanic. Get free pickup & drop, genuine spare parts and more.`
    // return `Are you looking for Car Service Center in ${city.name}, ${administrativeArea}. Find the nearest GoMechanic workshop for Car Service, Cleaning, Wheel Care, Denting, Painting and more.`;
  }

  if (city && selectedStore && storeLocation) {
    return `Book Car Service at Nearest Service Center ${storeName} in ${storeLocation} ${city.name} for Scheduled Maintenance, Tyres, Batteries, Denting, Painting, Washing or Insurance Claims.`;
  }

  if (city && serviceType && (cardetails.brand || cardetails.model || cardetails.fuel)) {
    if (descriptionGenerteData[`city_service-car-${serviceType.slug}`]) {
      return replaceTemplates(descriptionGenerteData[`city_service-car-${serviceType.slug}`],
        objForReplacer);
    }
  }

  if (city && serviceType && !(cardetails.brand || cardetails.model || cardetails.fuel)) {
    if (descriptionGenerteData[`city_service-${serviceType.slug}`]) {
      return replaceTemplates(descriptionGenerteData[`city_service-${serviceType.slug}`],
        objForReplacer);
    }
  }

  if (city) {
    if (descriptionGenerteData.city) {
      return replaceTemplates(descriptionGenerteData.city,
        objForReplacer);
    }
  }

  // with region, fuel
  if (city && selectedRegion && serviceType && cardetails.fuel && cardetails.model && cardetails.brand) {
    return `${serviceType.seoDescription} in ${selectedRegion.name}, ${city.name}`.replace('Book ', `Book ${cardetails.brand.name} ${cardetails.model.name} ${cardetails.fuel.name} `);
  }

  if (city && serviceType && cardetails.fuel && cardetails.model && cardetails.brand) {
    return `${serviceType.seoDescription} in ${city.name}`.replace('Book ', `Book ${cardetails.brand.name} ${cardetails.model.name} ${cardetails.fuel.name} `);
  }

  // with region, model
  if (city && selectedRegion && serviceType && cardetails.model && cardetails.brand) {
    return `${serviceType.seoDescription} in ${selectedRegion.name}, ${city.name}`.replace('Book ', `Book ${cardetails.brand.name} ${cardetails.model.name} `);
  }

  if (city && serviceType && cardetails.model && cardetails.brand) {
    return `${serviceType.seoDescription} in ${city.name}`.replace('Book ', `Book ${cardetails.brand.name} ${cardetails.model.name} `);
  }

  // with region, brand
  if (city && selectedRegion && serviceType && cardetails.brand) {
    return `${serviceType.seoDescription} in ${selectedRegion.name}, ${city.name}`.replace('Book ', `Book ${cardetails.brand.name} `);
  }

  if (city && serviceType && cardetails.brand) {
    return `${serviceType.seoDescription} in ${city.name}`.replace('Book ', `Book ${cardetails.brand.name} `);
  }

  if (city && serviceType && selectedRegion) return `${serviceType.seoDescription} in ${selectedRegion.name}, ${city.name}`;

  if (city && serviceType) return `${serviceType.seoDescription} in ${city.name}`;

  if (city && selectedRegion) {
    return replaceTemplates('Book General Car Service, Car Wheel Repair, Car Denting, Car Painting, Car Wash Services Online. Best Service Centers in <SubZone>, <City>',
      objForReplacer);
  }

  if (city) {
    return replaceTemplates('Book General Car Service, Car Wheel Repair, Car Denting, Car Painting, Car Wash Services Online. Best Service Centers in <City>',
      objForReplacer);
  }
};

export default descriptionGenerator;
