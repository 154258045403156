import { SET_WORKSHOP } from "../constants";

export default function workShop(state = null, action) {
    switch (action.type) {
      case SET_WORKSHOP:
        return action.payload.value;
      default:
        return state;
    }
  }
  