import { SET_APPLIED_COUPON } from '../constants';

export default function coupon(state = null, action) {
  switch (action.type) {
    case SET_APPLIED_COUPON: {
      return action.payload.value;
    }
    default:
      return state;
  }
}
