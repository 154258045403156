import { SET_BLOGS_LIST } from '../constants';

export default function blogs(state = null, action) {
  switch (action.type) {
    case SET_BLOGS_LIST:
      return action.payload.value;
    default:
      return state;
  }
}
