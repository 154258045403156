import { SET_LAZADA_SELECTED_SERVICES } from '../constants';

function lazadaSelectedServices(state = [], action) {
    switch (action.type) {
        case SET_LAZADA_SELECTED_SERVICES:
            return action.payload.value;
        default:
            return state;
    }
}

export default lazadaSelectedServices;
