import { SET_USER_CAR_DETAILS } from '../constants';
import StorageBrowser from '../_services/storageBrowser';

export default function setUserCar(value) {
  try {
    const storageBrowser = new StorageBrowser();
    storageBrowser.setStorageLs('cardetails', value);
  } catch (e) {
    console.error(e);
  }
  return {
    type: SET_USER_CAR_DETAILS,
    payload: {
      value,
    },
  };
}
