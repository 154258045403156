import { SET_DEVICE_TYPE } from '../constants';

export function setDeviceType(value) {
  return {
    type: SET_DEVICE_TYPE,
    payload: {
      value,
    },
  };
}
