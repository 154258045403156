import { SET_ACCESSORIES_CART_RESOLVED } from '../constants';

export default function accessoriesCartResolved(state = null, action) {
  switch (action.type) {
    case SET_ACCESSORIES_CART_RESOLVED:
      return action.payload.value;
    default:
      return state;
  }
}
