import { SET_FAQS_FOR_CITY } from '../constants';

export default function faqs(state = [], action) {
  switch (action.type) {
    case SET_FAQS_FOR_CITY:
      return action.payload.value;
    default:
      return state;
  }
}
