import HttpHeader from '../httpHeader';
import Global from '../../global';
import { Http } from '../../modules/http';

export const getModelsByBrand = (brand_id) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.api_get}v2/oauth/vehicles/get_models_by_brand/?brand_id=${brand_id}`.trim();
  return HttpHandler.get(url, headers);
};
