import { CHANGE_ACCESS_TOKEN, LOGIN_USER, UPDATE_USER_DETAILS } from '../constants';

export const changeAccessToken = (value) => ({
  type: CHANGE_ACCESS_TOKEN,
  payload: {
    value,
  },
});

export const saveUserDetails = (value) => ({
  type: UPDATE_USER_DETAILS,
  payload: {
    ...value,
  },
});

export const saveLoggedInUserDetails = (value) => ({
  type: LOGIN_USER,
  payload: {
    ...value,
  },
});
