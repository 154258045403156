import { replaceTemplates } from './descriptionGenerator';
import { titleData, mlyTitleData } from './titleData';
import history from '../../history';
import { getSitemapMetaTitle } from './directory-metatags';
import { isMalaysiaRequest } from '../../utilities';

const titleGenerator = (store, preDefinedTitle, forcePreDefinedTitle) => {
  if (!preDefinedTitle) preDefinedTitle = 'GoMechanic';
  if (forcePreDefinedTitle) return preDefinedTitle;

  const titleGenerteData = isMalaysiaRequest() ? mlyTitleData : titleData;
  const {
    city,
    serviceType,
    service,
    cardetails,
    selectedRegion,
    selectedStore,
    storeLocation,
    storePage,
    administrativeArea,
    storeName,
    retailAccessoriesPage
  } = store;

  const objForReplacer = {
    city: city || null,
    serviceType: serviceType || null,
    service: service || null,
    brand: (cardetails && cardetails.brand) ? cardetails.brand : null,
    model: (cardetails && cardetails.model) ? cardetails.model : null,
    fuel: (cardetails && cardetails.fuel) ? cardetails.fuel : null,
    subzone: selectedRegion || null,
  };

  if (history && history.location && history.location.pathname.includes("directory")) {
    const { pathname } = history.location;
    return getSitemapMetaTitle(pathname);
  }

  if(retailAccessoriesPage){
    return `Best Deals On Car Accessories In ${city?.name} | Buy Online | GoMechanic`;}

  if (!city) return preDefinedTitle;

  if (city && storePage) {
    return `Best Car Workshop in ${city.name} - GoMechanic`
    // return `GoMechanic Workshop in ${city.name}, ${administrativeArea}`;
  }

  if (city && selectedStore && storeLocation) {
    return storeName;
  }

  if (city && serviceType && (cardetails.brand || cardetails.model || cardetails.fuel)) {
    if (titleGenerteData[`city_service-car-${serviceType.slug}`]) {
      return replaceTemplates(titleGenerteData[`city_service-car-${serviceType.slug}`],
        objForReplacer);
    }
  }

  if (city && serviceType && !(cardetails.brand || cardetails.model || cardetails.fuel)) {
    if (titleGenerteData[`city_service-${serviceType.slug}`]) {
      return replaceTemplates(titleGenerteData[`city_service-${serviceType.slug}`],
        objForReplacer);
    }
  }

  if (city) {
    if (titleGenerteData.city) {
      return replaceTemplates(titleGenerteData.city,
        objForReplacer);
    }
  }

  if (city && selectedRegion && serviceType && cardetails.fuel && cardetails.model && cardetails.brand) {
    return `Best ${cardetails.brand.name} ${cardetails.model.name} ${cardetails.fuel.name} ${serviceType.seoTitle} in ${selectedRegion.name}, ${city.name} - GoMechanic`;
  }

  if (city && serviceType && cardetails.fuel && cardetails.model && cardetails.brand) {
    return `Best ${cardetails.brand.name} ${cardetails.model.name} ${cardetails.fuel.name} ${serviceType.seoTitle} in ${city.name} - GoMechanic`;
  }

  if (city && selectedRegion && serviceType && cardetails.model && cardetails.brand) {
    return `Best ${cardetails.brand.name} ${cardetails.model.name} ${serviceType.seoTitle} in ${selectedRegion.name}, ${city.name} - GoMechanic`;
  }

  if (city && serviceType && cardetails.model && cardetails.brand) {
    return `Best ${cardetails.brand.name} ${cardetails.model.name} ${serviceType.seoTitle} in ${city.name} - GoMechanic`;
  }

  if (city && selectedRegion && serviceType && cardetails.brand) {
    return `Best ${cardetails.brand.name} ${serviceType.seoTitle} in ${selectedRegion.name}, ${city.name} - GoMechanic`;
  }

  if (city && serviceType && cardetails.brand) {
    return `Best ${cardetails.brand.name} ${serviceType.seoTitle} in ${city.name} - GoMechanic`;
  }

  if (city && serviceType && selectedRegion) return `Best ${serviceType.seoTitle} in ${selectedRegion.name}, ${city.name} - GoMechanic`;

  if (city && serviceType) return `Best ${serviceType.seoTitle} in ${city.name} - GoMechanic`;

  if (city && selectedRegion) return `Best Car Service | Car Repair |  Car Cleaning in ${selectedRegion.name}, ${city.name} - GoMechanic`;

  if (city) return `Best Car Service | Car Repair |  Car Cleaning in ${city.name} - GoMechanic`;
};

export default titleGenerator;
