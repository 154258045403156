import { SET_ACTIVE_SEGMENT, SET_SEGMENTS } from '../constants';

function segmentList(state = null, action) {
  switch (action.type) {
    case SET_SEGMENTS:
      return action.payload.value;
    default:
      return state;
  }
}
function activeSegment(state = null, action) {
  switch (action.type) {
    case SET_ACTIVE_SEGMENT:
      return action.payload.value;
    default:
      return state;
  }
}

export { segmentList, activeSegment };
