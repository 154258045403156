import { SET_WARRANTY_SELECTED_TAB } from '../constants';

export default function warrantySelectedTab(state = null, action) {
  switch (action.type) {
    case SET_WARRANTY_SELECTED_TAB:
      return action.payload.value;
    default:
      return state;
  }
}
