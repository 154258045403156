import storeExport from '../clientStore';

const getPathWithUtm = (path) => {
  if (path.includes('accessories')) return path;
  const queryPart = path.split('?');
  let pathToReturn = `${queryPart[0]}`;
  const appState = storeExport.store.getState();
  const { initialQueryString } = appState;

  const paramsQueryStr = new URLSearchParams(initialQueryString);
  const paramObjQueryStr = {};
  for (const value of paramsQueryStr.keys()) {
    paramObjQueryStr[value] = paramsQueryStr.get(value);
  }

  const params = new URLSearchParams(queryPart[1]);
  let paramObj = {};
  for (const value of params.keys()) {
    paramObj[value] = params.get(value);
  }
  paramObj = { ...paramObj, ...paramObjQueryStr };
  const allKeys = Object.keys(paramObj);
  for (let i = 0; i < allKeys.length; i++) {
    if (i === 0) {
      pathToReturn += '?';
    }
    pathToReturn += `${i === 0 ? '' : '&'}${allKeys[i]}=${paramObj[allKeys[i]]}`;
  }
  return pathToReturn;
};

const autoUtmHistory = (historyObj) => ({
  push: (path, state) => historyObj.push(getPathWithUtm(path), state),
  replace: (path, state) => historyObj.replace(getPathWithUtm(path, state)),
});

export default autoUtmHistory;
