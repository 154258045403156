import { SET_SELECTED_PAYMENT_METHOD } from '../constants';
const paymentMethods = {
  CASH: 'CASH',
  ONLINE: 'ONLINE',
};
export default function selectedPaymentMethod(state = paymentMethods.ONLINE, action) {
  switch (action.type) {
    case SET_SELECTED_PAYMENT_METHOD: {
      return action.payload.value;
    }
    default:
      return state;
  }
}
