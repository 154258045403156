import { SET_WARRANTY_DETAILS_DATA } from '../constants';


export default function warrantyDetails(state = null, action) {
  // console.log(action)
  switch (action.type) {
    case SET_WARRANTY_DETAILS_DATA: {
      return action.payload.value;
    }
    default:
      return state;
  }
}
