import { SET_STORE_LOCATION } from '../constants';

export default function storeLocation(state = null, action) {
  switch (action.type) {
    case SET_STORE_LOCATION:
      return action.payload.value;
    default:
      return state;
  }
}
