/*

This component will show a toast as on
https://app.zeplin.io/project/5da0780780829f083448125a/screen/5da07ead8acfab3091c4ea2a
or on Apply coupon page

Component expect props :- text (string), time (in milliseconds) and tickIcon (boolean)

tickIcon can be disabled to make it reusable on apply coupon page.

 */

import React, { useEffect, useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './Toast.css';

const Toast = (props) => {
  useStyles(s);
  const [display, setDisplay] = useState(true);
  useEffect(() => {
    setTimeout(() => { setDisplay(false); props.hideHandler({ ...props, show: false }); }, props.time ? props.time : 1500);
  }, []);
  return (
    <>
      {display ? (
        <div
          className={s.container}
          style={props.style}
        >
          <div>
            {props.tickIcon && (
              <img
                loading="lazy"
                style={{
                  marginRight: '10px',
                }}
                src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Admin/75A364CC-458A-4C5A-91E2-53AFB1CD4ACD.svg"
                alt="Toast"
              />
            )}
            {props.text}
          </div>
        </div>
      ) : null}
    </>
  );
};
export default Toast;
