import HttpHeader from '../../httpHeader';
import Global from '../../../global';
import { Http } from '../../../modules/http';

const getObservable = (url, cartData) => {
  const Headers = new HttpHeader();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  return HttpHandler.post(
    url,
    {
      ...cartData,
    },
    headers,
  );
};
export const cartManageAddToCart = (cartData) => {
  const ApiManager = new Global();
  const url = `${ApiManager.urls.api_post}v3/oauth/customer/add_to_cart`.trim();
  return getObservable(url, cartData);
};

export const cartManageAddToCartV4 = (cartData) => {
  const ApiManager = new Global();
  const url = `${ApiManager.urls.api_post}v2/oauth/customer/add_to_cart_v4`.trim();
  return getObservable(url, cartData);
};

export const cartManageRemoveCart = (cartData) => {
  const ApiManager = new Global();
  const url = `${ApiManager.urls.api_post}v3/oauth/customer/remove_from_cart`.trim();
  return getObservable(url, cartData);
};

export const cartManageRemoveCartV4 = (cartData) => {
  const ApiManager = new Global();
  const url = `${ApiManager.urls.api_post}v2/oauth/customer/remove_from_cart_v4`.trim();
  return getObservable(url, cartData);
};

export const cartManageApplyOrRemoveCoupon = (cartData) => {
  const ApiManager = new Global();
  const url = `${ApiManager.urls.api_post}v3/oauth/customer/apply_coupon_discount`.trim();
  return getObservable(url, cartData);
};

export const cartManageChangeCity = (cartData) => {
  const ApiManager = new Global();
  const url = `${ApiManager.urls.api_post}v3/oauth/customer/change_cart_city`.trim();
  return getObservable(url, cartData);
};

export const cartManageChangeCityV4 = (cartData) => {
  const ApiManager = new Global();
  const url = `${ApiManager.urls.api_post}v2/oauth/customer/change_cart_city_v4`.trim();
  return getObservable(url, cartData);
};

export const cartManageChangeCar = (cartData) => {
  const ApiManager = new Global();
  const url = `${ApiManager.urls.api_post}v3/oauth/customer/change_cart_car`.trim();
  return getObservable(url, cartData);
};

export const cartManageChangeCarV4 = (cartData) => {
  const ApiManager = new Global();
  const url = `${ApiManager.urls.api_post}v2/oauth/customer/change_cart_car_v4`.trim();
  return getObservable(url, cartData);
};

export const goAppMoneyApplyOrRemove = (value) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.api_get}v3/oauth/customer/apply_gom_discount?&action=${value}`;
  return HttpHandler.get(url, headers);
}