import { SET_PRODUCT_DETAILS_PAGE } from '../constants';

const productDetailsPage = (state = {}, action) => {
  switch (action.type) {
    case SET_PRODUCT_DETAILS_PAGE:
      return action.payload.value;
    default:
      return state;
  }
};


export default productDetailsPage;
