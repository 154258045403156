import { SET_ACC_SEARCH_DATA } from '../constants';

export default function accSearchData(state = {}, action) {
  switch (action.type) {
    case SET_ACC_SEARCH_DATA: {
      return action.payload.value;
    }
    default:
      return state;
  }
}
