import { SET_CATEGORY_PAGE_DETAILS } from '../constants';

const categoryPageDetails = (state = {}, action) => {
  switch (action.type) {
    case SET_CATEGORY_PAGE_DETAILS:
      return action.payload.value;
    default:
      return state;
  }
};


export default categoryPageDetails;
