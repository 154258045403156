import HttpHeader from '../httpHeader';
import { Http } from '../../modules/http';

export const getReviewsCityWise = (cityId, categorySlug) => {
  const Headers = new HttpHeader();
  const HttpHandler = new Http();

  let path = '';
  if (typeof window !== 'undefined') {
    path = window.location.pathname;
  }

  const headers = Headers.get_token_headers();
  const url = `/api/v1/get-reviews-city-wise/?cityId=${cityId}${categorySlug && categorySlug.length ? `&categorySlug=${categorySlug}` : ''}&path=${path}`;
  return HttpHandler.get(url, headers);
};
