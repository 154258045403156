import { SET_OBD_DETAILS_DATA } from '../constants';

export default function obdDetails(state = null, action) {
  // console.log(action)
  switch (action.type) {
    case SET_OBD_DETAILS_DATA: {
      return action.payload.value;
    }
    default:
      return state;
  }
}
