import { SET_USER_SELECTED_CAR_ID } from '../constants';

export function setUserSelectedCarId (value) {
  return {
    type: SET_USER_SELECTED_CAR_ID,
    payload: {
      value,
    },
  };
}
