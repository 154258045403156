import { SET_DISPLAY_CONFIGURATION } from '../constants';

export default function displayConfigurationReducer(state = {}, action) {
  switch (action.type) {
    case SET_DISPLAY_CONFIGURATION:
      return { ...state, ...action.payload.value };
    default:
      return state;
  }
}
