import { SET_ACCESSORIES_CITIES } from '../constants';

function accCities(state = {}, action) {
  switch (action.type) {
    case SET_ACCESSORIES_CITIES:
      return action.payload.value;
    default:
      return state;
  }
}

export default accCities;
