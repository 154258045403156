import { SET_USER_REMARKS } from '../constants';

export default function setUserRemarks(state = '', action) {
  switch (action.type) {
    case SET_USER_REMARKS: {
      return action.payload.value;
    }
    default:
      return state;
  }
}
