import { SET_USER_CART } from '../constants';

export function setCart(value) {
  return {
    type: SET_USER_CART,
    payload: {
      value,
    },
  };
}
