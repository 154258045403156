import { SET_DISPLAY_POSSIBLE_SECTION } from '../constants';

export default function displayPossibleSctions(state = {}, action) {
  switch (action.type) {
    case SET_DISPLAY_POSSIBLE_SECTION:
      return { ...state, ...action.payload.value };
    default:
      return state;
  }
}
