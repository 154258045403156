import { SET_ACC_BRAND_PAGE_DETAILS } from '../constants';

const accBrandPageDetails = (state = {}, action) => {
  switch (action.type) {
    case SET_ACC_BRAND_PAGE_DETAILS:
      return action.payload.value;
    default:
      return state;
  }
};


export default accBrandPageDetails;
