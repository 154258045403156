/* eslint-disable camelcase */
import { async } from 'rxjs/internal/scheduler/async';
import * as events from './eventList';
import { isMalaysiaRequest } from '../utilities';

const fireEvent = (name, data) => {
  try {
    if (typeof window !== 'undefined') {
      // window.gtag('event', name, {
      //   ...data,
      // });
      // if (window.fbq) {
      if (window.dataLayer) {
        // window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: name,
          ...data,
          _clear: true,
        });
      }
      // }
    }
  } catch (e) {
    console.error(e);
  }
};

const getCountry = () => {
  if (isMalaysiaRequest()) {
    return 'MS';
  }
  return 'IN';
};
export const selectCarBrandGtmEvent = async (user_id, fire_screen, brand_name, brand_id) => {
  fireEvent(events.select_brand, {
    user_id, fire_screen, brand_name, brand_id,
  });
};

export const selectCarModelGtmEvent = async (user_id, fire_screen, car_name, car_id, brand_name, brand_id) => {
  fireEvent(events.select_model, {
    user_id, fire_screen, car_name, car_id, brand_name, brand_id,
  });
};

export const tapCheckPriceGtmEvent = async (
  user_id, fire_screen, fuel_type, car_name, car_id, brand_name, brand_id, mobile,
) => {
  fireEvent(events.tap_check_price, {
    user_id, fire_screen, fuel_type, car_name, car_id, brand_name, brand_id, mobile,
  });
};

export const downloadAppGtmEvent = async ({ app }) => {
  fireEvent(events.download_app, { app, country: getCountry() });
};

export const addToCartGtmEvent = async ({
  user_id, fire_screen, item_name, item_id,
  category, sub_category, miles, car_id, value, engine_oil, boosters,
}) => {
  fireEvent(events.add_to_cart, {
    user_id,
    fire_screen,
    item_name,
    item_id,
    category,
    sub_category,
    miles,
    car_id,
    value,
    engine_oil,
    boosters,
    country: getCountry(),
  });
};

export const popularServiceGtmEvent = async ({
  category_id, item_id, category, item,
}) => {
  fireEvent(events.tap_popular_service, {
    category_id, item_id, category, item, country: getCountry(),
  });
};

export const tapSearchGtmEvent = async (user_id, car_id, fire_screen) => {
  fireEvent(events.tap_searches, {
    user_id, car_id, fire_screen, country: getCountry(),
  });
};

export const viewItemGtmEvent = async (
  user_id, item_name, item_id, category_name, category_id, car_id,
) => {
  fireEvent(events.view_item, {
    user_id, item_name, item_id, category_name, category_id, car_id,
  });
};

export const viewItemListGtmEvent = async (
  user_id, category_name, category_id, car_id,
) => {
  fireEvent(events.view_item_list, {
    user_id, category_name, category_id, car_id,
  });
};

export const viewCartGtmEvent = async (
  user_id, fire_screen, cart_items, cart_value, car_id,
) => {
  fireEvent(events.view_cart, {
    user_id, fire_screen, cart_items, cart_value, car_id,
  });
};

export const retailBeginCheckoutGtmEvent = async (
  user_id, item_name, item_id, total_amount, car_name, car_id, coupon_name, coupon_discount, go_app_discount, miles,
) => {
  fireEvent(events.begin_checkout, {
    user_id, item_name, item_id, total_amount, car_name, car_id, coupon_name, coupon_discount, go_app_discount, miles,
  });
};

export const tapOnMonsoonList = async ({ category_id, category }) => {
  fireEvent(events.tap_on_monsoon_list, {
    category_id, category, country: getCountry(),
  });
};

export const loginGtmEvent = async ({
  phoneNumber, success, fire_screen, user_id, truecaller,
}) => {
  fireEvent(events.login, {
    phoneNumber,
    ...(fire_screen ? { fire_screen } : {}),
    ...(user_id ? { user_id } : {}),
    ...(typeof (success) !== 'undefined' ? { successful: success } : {}),
    truecaller: typeof (truecaller) !== 'undefined',
  });
};

export const signupGtmEvent = async ({ page_name, city_id, source }) => {
  fireEvent(events.sign_up, {
    page_name, city_id, source,
  });
};

export const purchaseEvent = async ({
  user_id, item_name, item_id, total_amount, car_name, car_id, miles, coupon_name, order_id,
}) => {
  fireEvent(events.purchase, {
    user_id, item_name, item_id, total_amount, car_name, car_id, miles, coupon_name, order_id,
  });
};

export const luxPurchaseEvent = async ({
  user_id, item_name, item_id, total_amount, car_name, car_id, miles, coupon_name, order_id, go_app_discount,
}) => {
  fireEvent(events.lux_purchase, {
    user_id, item_name, item_id, total_amount, car_name, car_id, miles, coupon_name, order_id, go_app_discount,
  });
};

export const tapMilesMembership = async ({ userId, status }) => {
  fireEvent(events.tap_miles_membership, {
    userId, status, country: getCountry(),
  });
};

export const tapHomeActivatedMiles = async ({ userId }) => {
  fireEvent(events.tap_activated_miles, {
    userId, country: getCountry(),
  });
};

export const tapAnimation = async (user_id, category_name, category_id, item_name, item_id, car_id) => {
  fireEvent(events.tap_animation, {
    user_id, category_name, category_id, item_name, item_id, car_id, country: getCountry(),
  });
};

export const couponApplyBtnEvent = async (user_id, coupon_name, coupon_type, car_id, miles) => {
  fireEvent(events.init_coupon, {
    user_id, coupon_name, coupon_type, car_id, miles,
  });
};

export const applyCouponSuccessEvent = async (user_id, coupon_name, coupon_type, success, discount_value, car_id, miles) => {
  fireEvent(events.apply_coupon, {
    user_id, coupon_name, coupon_type, success, discount_value, car_id, miles,
  });
};

export const tapBoosters = async (user_id, car_id, item_name, item_id, miles) => {
  fireEvent(events.tap_booster, {
    user_id, car_id, item_name, item_id, miles,
  });
};

export const tapSideMenu = async () => {
  fireEvent(events.tap_side_menu, { country: getCountry() });
};

export const viewSideMenu = async ({ fire_screen }) => {
  fireEvent(events.view_side_menu, { fire_screen, country: getCountry() });
};

export const tapExpertVideos = async ({ user_id, city_id, car_model }) => {
  fireEvent(events.tap_expert_videos, {
    user_id, city_id, car_model, country: getCountry(),
  });
};

export const viewSearchResults = async (user_id, car_id, search_term) => {
  fireEvent(events.view_search_results, {
    user_id, car_id, search_term, country: getCountry(),
  });
};

export const searchClicked = async (user_id, car_id, search_term, search_clicked) => {
  fireEvent(events.search_clicked, {
    user_id, car_id, search_term, search_clicked, country: getCountry(),
  });
};

export const intChat = async ({ user_id, city_id, car_model }) => {
  fireEvent(events.int_chat, {
    user_id, city_id, car_model, country: getCountry(),
  });
};

export const endChat = async ({ user_id }) => {
  fireEvent(events.end_chat, { user_id, country: getCountry() });
};

export const midBannerClick = async () => {
  fireEvent(events.mid_banner_click, { country: getCountry() });
};

export const tapGomAssurance = async () => {
  fireEvent(events.tap_gom_assurance, { country: getCountry() });
};

export const tapMilesEntry = async ({ user_id, fire_screen, car_id }) => {
  fireEvent(events.web_tap_miles_banner, { user_id, fire_screen, car_id });
};

export const tapCuratedCustomRepairs = async ({
  user_id, custom_repair_name, deeplink, page_name,
}) => {
  fireEvent(events.tap_curated_custom_repairs, {
    user_id, custom_repair_name, deeplink, page_name,
  });
};

export const tapBack = async ({ user_id, page_name }) => {
  fireEvent(events.tap_back, { user_id, page_name });
};

export const tapCoverageDetails = async ({
  user_id, car_id, service_name, page_name,
}) => {
  fireEvent(events.tap_coverage_details, {
    user_id, car_id, service_name, page_name,
  });
};

export const tapCoverageDetailsSubTabs = async ({
  user_id, sub_tab_name, car_id, page_name, service_name,
}) => {
  fireEvent(events.tap_coverage_details_sub_tabs, {
    user_id, sub_tab_name, car_id, page_name, service_name,
  });
};

export const tapClose = async ({ user_id, page_name, service_name }) => {
  fireEvent(events.tap_close, { user_id, page_name, service_name });
};

export const tapCoverageNname = async ({
  user_id, coverage_name, car_id, page_name,
}) => {
  fireEvent(events.tap_coverage_name, {
    user_id, coverage_name, car_id, page_name,
  });
};

export const tapSeeMore = async ({
  user_id, coverage_name, car_id, page_name, service_name,
}) => {
  fireEvent(events.tap_see_more, {
    user_id, coverage_name, car_id, page_name, service_name,
  });
};

export const tapWarrantyFaqs = async ({
  user_id, car_id, faq_title, page_name,
}) => {
  fireEvent(events.tap_warranty_faqs, {
    user_id, car_id, faq_title, page_name,
  });
};

export const tapWarrantyEntry = async (user_id, fire_screen = 'homepage', car_id) => {
  fireEvent(events.web_tap_warranty_banner, { user_id, fire_screen, car_id });
};

export const tapServiceSubCategory = async ({
  user_id, sub_category_name, service_name, page_name,
}) => {
  fireEvent(events.tap_service_sub_category, {
    user_id, sub_category_name, service_name, page_name,
  });
};

export const tapSeeLess = async ({ user_id, page_name }) => {
  fireEvent(events.tap_see_less, { user_id, page_name });
};

export const tapClaim = async ({ user_id, page_name, service_name }) => {
  fireEvent(events.tap_claim, { user_id, page_name, service_name });
};

export const tapBookClaim = async ({ user_id, page_name, service_name }) => {
  fireEvent(events.tap_book_claim, { user_id, page_name, service_name });
};

export const clickBook = async ({ user_id, page_name, service_name }) => {
  fireEvent(events.click_book, { user_id, page_name, service_name });
};

export const addDescription = async ({ description }) => {
  fireEvent(events.add_description, { description, country: getCountry() });
};
// export const accessoriesBannerClickGtmEvent = async ({
//   position, banner_id, segment_id, segment_name, sub_segment_id,
//   sub_segment_name, item_id, item_name, redirect, car_brand, user_id, pagepath, city,
// }) => {
//   fireEvent(events.click_banner, {
//     position,
//     banner_id,
//     segment_id,
//     segment_name,
//     sub_segment_id,
//     sub_segment_name,
//     item_id,
//     item_name,
//     redirect,
//     car_brand,
//     user_id,
//     pagepath,
//     city,
//   });
// };

export const accessoriesSearchGtmEvent = async ({
  term, car_brand, user_id, pagepath, city, fire_screen,
}) => {
  fireEvent(events.search_bar_acc, {
    term, car_brand, user_id, pagepath, city, fire_screen,
  });
};

export const serviceSelectGtmEvent = async ({
  category_id, category_name, car_brand, user_id, pagepath, city,
}) => {
  fireEvent(events.select_cat_scroll_acc, {
    category_id, category_name, car_brand, user_id, pagepath, city,
  });
};

export const accessoriesExploreClickGtmEvent = async ({
  car_brand, user_id, pagepath, city,
}) => {
  fireEvent(events.cta_explore, {
    car_brand, user_id, pagepath, city,
  });
};

export const segmentSelectGtmEvent = async ({
  segment_id, segment_name, car_brand, user_id, pagepath, city, position,
}) => {
  fireEvent(events.select_top_category, {
    segment_id,
    segment_name,
    car_brand,
    user_id,
    pagepath,
    city,
    firescreen: position || '',
  });
};

export const subSegmentSelectGtmEvent = async ({
  segment_id, segment_name, category_id, category_name, fire_screen, position,
}) => {
  fireEvent(events.select_sub_category, {
    segment_id,
    segment_name,
    category_id,
    category_name,
    fire_screen,
    position,
  });
};

export const accessoriesProductsSectionClickGtmEvent = async ({
  section_type, position, segment_id, segment_name, sub_segment_id, sub_segment_name,
  item_id, item_name, car_brand, user_id, pagepath, city,
}) => {
  fireEvent(events.select_product_page_section, {
    section_type,
    position,
    segment_id,
    segment_name,
    sub_segment_id,
    sub_segment_name,
    item_id,
    item_name,
    car_brand,
    user_id,
    pagepath,
    city,
  });
};

// export const accessoriesAddToCartGtmEvent = async ({
//   segment_id, segment_name, sub_segment_id, sub_segment_name,
//   quantity, item_name, item_id, total_value, discount_net_value, currency, car_brand, user_id, pagepath, city,
// }) => {
//   fireEvent(events.cta_add_to_cart_acc, {
//     segment_id,
//     segment_name,
//     sub_segment_id,
//     sub_segment_name,
//     quantity,
//     item_name,
//     item_id,
//     total_value,
//     discount_net_value,
//     currency,
//     car_brand,
//     user_id,
//     pagepath,
//     city,
//   });
// };

export const accessoriesBuyNowGtmEvent = async ({
  segment_id, segment_name, sub_segment_id, sub_segment_name,
  quantity, item_name, item_id, value, currency,
}) => {
  fireEvent(events.cta_buy_now, {
    segment_id,
    segment_name,
    sub_segment_id,
    sub_segment_name,
    quantity,
    item_name,
    item_id,
    value,
    currency,
  });
};

// export const accessoriesViewItemGtmEvent = async ({
//   segment_id, segment_name, sub_segment_id, sub_segment_name,
//   item_name, item_id, value, car_brand, user_id, pagepath, city,
// }) => {
//   fireEvent(events.view_item_acc, {
//     segment_id,
//     segment_name,
//     sub_segment_id,
//     sub_segment_name,
//     item_name,
//     item_id,
//     value,
//     car_brand,
//     user_id,
//     pagepath,
//     city,
//   });
// };

// export const accessoriesPurchaseGtmEvent = async ({
//   item_list, total_value, discount_net_value, currency,
//   coupon_id, order_id, transaction_id, car_brand, user_id, pagepath, city,
// }) => {
//   fireEvent(events.cta_purchase_acc, {
//     item_list,
//     total_value,
//     discount_net_value,
//     transaction_id,
//     currency,
//     coupon_id,
//     order_id,
//     car_brand,
//     user_id,
//     pagepath,
//     city,
//   });
// };

// export const accessoriesCheckoutGtmEvent = async ({
//   item_list, total_value, discount_net_value, currency, car_brand, user_id, pagepath, city,
// }) => {
//   fireEvent(events.cta_begin_checkout_acc, {
//     item_list, total_value, discount_net_value, currency, car_brand, user_id, pagepath, city,
//   });
// };

export const accessoriesAppyCouponGtmEvent = async ({
  coupon_id, user_id,
}) => {
  fireEvent(events.apply_coupon_acc, {
    coupon_id, user_id,
  });
};

export const accessoriesFilterClickGtmEvent = async ({
  sub_segment_id, sub_segment_name, filter_name, filter_value,
}) => {
  fireEvent(events.filter_acc, {
    sub_segment_id, sub_segment_name, filter_name, filter_value,
  });
};

export const beatEstimateKnowMore = async ({
  pageurl, city,
}) => {
  fireEvent(events.cta_know_more_beat, {
    pageurl, city,
  });
};

export const beatEstimateSubmitNow = async ({
  pageurl, city,
}) => {
  fireEvent(events.cta_submit_now_beat, {
    pageurl, city,
  });
};

export const gomIcon = async (fire_screen, car_id, user_id) => {
  fireEvent(events.tap_gom_icon, {
    country: getCountry(), fire_screen: fire_screen || '', car_id, user_id,
  });
};

export const dateAndTimeGtm = async (user_id, car_id, pickup_time, date, miles) => {
  fireEvent(events.data_time, {
    user_id, car_id, date, pickup_time, miles,
  });
};

export const dateGtmEvent = async (user_id, date, car_id, miles) => {
  fireEvent(events.select_date, {
    user_id, date, car_id, miles,
  });
};

export const timeGtmEvent = async (user_id, car_id, pickup_time, time_slot, miles) => {
  fireEvent(events.select_time, {
    user_id, car_id, pickup_time, time_slot, miles,
  });
};

export const addresschangeGtmEvent = async (user_id, car_id) => {
  fireEvent(events.web_click_change_address, { user_id, car_id, country: getCountry() });
};

export const arrivalModeGtm = async ({
  mode,
}) => {
  fireEvent(events.arrival_mode, {
    mode,
  });
};

export const removeItemGtm = async ({
  user_id, item_name, item_id, category, sub_category, miles, car_id, value, engine_oil, boosters,
}) => {
  fireEvent(events.remove_item, {
    user_id, item_name, item_id, category, sub_category, miles, car_id, value, engine_oil, boosters,
  });
};

export const paymentModeGtm = async (user_id, car_id, payment_mode, payment_amount, miles, payment_mode_option = null) => {
  fireEvent(events.payment_mode, {
    user_id, car_id, payment_mode, payment_amount, miles, payment_mode_option,
  });
};

// enhanced ecommerce event starts

export const accessoriesPromotionImpressionsEvent = async ({
  promotionList,
}) => {
  fireEvent(events.view_promotion, {
    ecommerce: {
      promoView: {
        promotions: [
          ...promotionList,
        ],
      },
    },
  });
};

export const accessoriesPromotionClickEvent = async ({
  promotion,
}) => {
  fireEvent(events.promotion_click, {
    ecommerce: {
      promoClick: {
        promotions: [{
          ...promotion,
        }],
      },
    },
  });
};

export const accessoriesProductImpressionsEvent = async ({
  productList,
}) => {
  fireEvent(events.view_impression, {
    ecommerce: {
      impressions: [
        ...productList,
      ],
    },
  });
};

export const accessoriesProductClickEvent = async ({
  product, list,
}) => {
  fireEvent(events.impression_click, {
    ecommerce: {
      click: {
        actionField: {
          list,
        },
        products: [{
          ...product,
        }],
      },
    },
  });
};

export const accessoriesProductDetailsViewEvent = async ({
  product,
}) => {
  fireEvent(events.view_detail, {
    ecommerce: {
      detail: {
        products: [{
          ...product,
        }],
      },
    },
  });
};

export const accessoriesAddToCartGtmEvent = async ({
  product, value, email,
}) => {
  fireEvent(events.acc_add_to_cart, {
    ecommerce: {
      currencyCode: 'INR',
      add: {
        actionField: {
          revenue: value,
          email,
        },
        products: [
          {
            ...product,
          },
        ],
      },
    },
  });
};

export const accessoriesRemoveFromCartGtmEvent = async ({
  product,
}) => {
  fireEvent(events.remove_from_cart, {
    ecommerce: {
      currencyCode: 'INR',
      remove: {
        products: [{
          ...product,
        }],
      },
    },
  });
};

export const accessoriesCheckoutProgressEvent = async ({
  value, items, checkout_step, option, email, phoneNumber,
}) => {
  fireEvent(events.checkout_progress, {
    ecommerce: items && items.length
      ? {
        currencyCode: 'INR',
        checkout: {
          actionField: {
            step: checkout_step,
            option,
            revenue: value,
            email,
            phoneNumber,
          },
          products: [
            ...items,
          ],
        },
      } : {
        currencyCode: 'INR',
        checkout: {
          actionField: {
            step: checkout_step,
            option,
            email,
            phoneNumber,
          },
        },
      },
  });
};

// export const accessoriesSetCheckoutOptionEvent = async ({
//   step, option,
// }) => {
//   fireEvent(events.set_checkout_option, {
//     ecommerce: {
//       checkout_option: {
//         actionField: {
//           step,
//           option,
//         },
//       },
//     },
//   });
// };

export const accessoriesPurchaseGtmEvent = async ({
  order_id, value, currency, tax, shipping, items, coupon, payment_method, email, phoneNumber,
}) => {
  fireEvent(events.acc_purchase, {
    ecommerce: {
      currencyCode: currency,
      acc_order_id: order_id,
      purchase: {
        actionField: {
          id: order_id,
          revenue: value,
          tax,
          shipping,
          coupon,
          payment_method,
          email,
          phoneNumber,
        },
        products: [
          ...items,
        ],
      },
    },
  });
};

// export const accessoriesContinueToPayEvent = async ({
//   user_id,
// }) => {
//   fireEvent(events.cta_continue_pay, {

//   });
// };

export const accessoriesCarSelectionEvent = async ({
  user_id,
}) => {
  fireEvent(events.select_car, {
    ecommerce: {
      user_id,
    },
  });
};

export const accessoriesCarBrandSelectionEvent = async ({
  user_id, car_brand_name,
}) => {
  fireEvent(events.select_car_brand, {
    ecommerce: {
      user_id,
      car_brand_name,
    },
  });
};

export const accessoriesCarModelSelectionEvent = async ({
  user_id, car_brand_name, car_model_name,
}) => {
  fireEvent(events.select_car_model, {
    ecommerce: {
      user_id,
      car_brand_name,
      car_model_name,
    },
  });
};

export const accessoriesCarFuelSelectionEvent = async ({
  user_id, car_brand_name, car_model_name, fuel_type,
}) => {
  fireEvent(events.select_fuel_type, {
    ecommerce: {
      user_id,
      car_brand_name,
      car_model_name,
      fuel_type,
    },
  });
};

export const accessoriesCarYearSelectionEvent = async ({
  user_id, car_brand_name, car_model_name, fuel_type, manufacture_year,
}) => {
  fireEvent(events.select_year, {
    ecommerce: {
      user_id,
      car_brand_name,
      car_model_name,
      fuel_type,
      manufacture_year,
    },
  });
};

export const accessoriesPaymentModeEvent = async ({
  user_id, payment_method, currency, coupon,
}) => {
  fireEvent(events.payment_mode_acc, {
    ecommerce: {
      user_id,
      payment_method,
      currency,
      coupon,
    },
  });
};

export const accessoriesNewAddressEvent = async ({
  user_id,
}) => {
  fireEvent(events.new_address, {
    ecommerce: {
      user_id,
    },
  });
};
// enhanced ecommerce event ends

export const nearMeLeadMobileSubmit = async (data) => {
  fireEvent(events.tap_next_acc, {
    ...data,
  });
};

export const nearMeLeadCitySubmit = async (data) => {
  fireEvent(events.cta_submit_acc, {
    ...data,
  });
};

export const enquiryPhoneNumberEvent = async (data) => {
  fireEvent(events.enq_enter_number, {
    ...data,
  });
};

export const enquiryLocationEvent = async (data) => {
  fireEvent(events.enq_enter_location, {
    ...data,
  });
};

export const enquiryRequestCallEvent = async (data) => {
  fireEvent(events.enq_request_call, {
    ...data,
  });
};

export const enquiryTapCallEvent = async (data) => {
  fireEvent(events.enq_tap_call, {
    ...data,
  });
};

export const enquiryTagFaqEvent = async (data) => {
  fireEvent(events.enq_tap_faq, {
    ...data,
  });
};

export const selectAccAddress = async (userId) => {
  fireEvent(events.select_address_acc, {
    user_id: userId,
  });
};

export const submitEmailGTmEvent = async (userId, email) => {
  fireEvent(events.submit_email, {
    user_id: userId,
    email_id: email,
  });
};

export const viewAccCartGtmEvent = async (user_id, products, cart_value, position, fire_screen) => {
  fireEvent(events.view_cart, {
    user_id, products, cart_value, position, fire_screen,
  });
};

export const prodceedToPayGtmEvent = async (user_id, products, total_items) => {
  fireEvent(events.proceed_pay, {
    user_id, products, total_items,
  });
};

export const initiateAccLoginGtmEvent = async (fire_screen) => {
  fireEvent(events.init_login, {
    fire_screen,
  });
};

export const getAccOtpGtmEvent = async (fire_screen) => {
  fireEvent(events.get_otp, {
    fire_screen,
  });
};

export const resendAccOtpGtmEvent = async (fire_screen) => {
  fireEvent(events.resend_otp, {
    fire_screen,
  });
};

export const accBannerClickGtmEvent = async (user_id, banner_id, scroll, banner_position) => {
  fireEvent(events.click_banner, {
    user_id, banner_id, scroll, banner_position,
  });
};

export const accViewTrendingGtmEvent = async (
  user_id, trending_category, item_id, item_name, trending_category_id,
) => {
  fireEvent(events.view_trending_products, {
    user_id, trending_category, item_id, item_name, trending_category_id,
  });
};

export const accTapSearchByBrandModelGtmEvent = async (search_by, brand_name, model_name) => {
  fireEvent(events.tap_search_by, {
    search_by, brand_name, model_name,
  });
};

export const accTapAddressGtmEvent = async (selected_address, user_id) => {
  fireEvent(events.tap_address_selection, {
    selected_address, user_id,
  });
};

export const railItemClickGtmEvent = async ({
  rails_name, scroll, product_name, product_id, product_value, user_id,
}) => {
  fireEvent(events.items_clicked, {
    rails_name, scroll, product_name, product_id, product_value, user_id,
  });
};

export const railsViewAllGtmEvent = async (rails_name, user_id) => {
  fireEvent(events.tap_rails_view_all, {
    rails_name, user_id,
  });
};

export const fetchLocationGtmEvent = async (user_id) => {
  fireEvent(events.fetch_my_location, {
    user_id,
  });
};

export const changeAddressGtmEvent = async (user_id) => {
  fireEvent(events.change_address, {
    user_id,
  });
};

export const addCartRecommendedGtmEvent = async (productData) => {
  fireEvent(events.add_recommended, {
    ...productData,
  });
};

export const addFrequentlyBoughtGtmEvent = async (productData) => {
  fireEvent(events.add_frequent, {
    ...productData,
  });
};

export const accSearchItemClick = async (search_clicked, search_term, fire_screen) => {
  fireEvent(events.search_click, {
    search_clicked,
    search_term,
    fire_screen,
  });
};

export const logoutGtmEvent = async ({ phoneNumber, user_id }) => {
  fireEvent(events.logout, {
    phoneNumber,
    user_id,
  });
};

export const tapBackGtmEvent = async (data) => {
  fireEvent(events.tap_back_detail, {
    ...data,
  });
};

export const accEnterSubscriberGtmEvent = async () => {
  fireEvent(events.enter_subscriber, {});
};

export const accSubmitSubscriberGtmEvent = async (data) => {
  fireEvent(events.submit_subscriber, {
    ...data,
  });
};

export const accNotifyMeGtmEvent = async (data) => {
  fireEvent(events.notify_me, {
    ...data,
  });
};

export const accRegisterWarrantyGtmEvent = async (data) => {
  fireEvent(events.acc_register_warranty, {
    ...data,
  });
};

export const accTapHamburgerMenuEvent = async () => {
  fireEvent(events.tap_hamburger, {});
};

export const accTapMyOrderEvent = async () => {
  fireEvent(events.tap_my_order, {});
};

export const accInitSearchEvent = async (fire_screen) => {
  fireEvent(events.init_search, { fire_screen });
};

export const accPopularSearchClickEvent = async (keyword, fire_screen) => {
  fireEvent(events.search_popular_searches, {
    popular_search_clicked: keyword,
    fire_screen,
  });
};
export const accTopSearchProductClickEvent = async (product, fire_screen) => {
  fireEvent(events.search_top_products, {
    top_searched_clicked: product.title,
    id: product.product_id,
    fire_screen,
  });
};

export const accTopBannerClickEvent = async (fire_screen, scroll, pageData) => {
  fireEvent(events.select_top_banner, {
    fire_screen,
    scroll,
    ...(pageData ? { pageData } : {}),
  });
};

export const accFooterCategoryClickEvent = async (popular_category, category_id) => {
  fireEvent(events.select_popular_categories, {
    popular_category,
    category_id,
  });
};

export const accFooterCarBrandClickEvent = async (popular_brand, brand_id) => {
  fireEvent(events.select_popular_brands, {
    popular_brand,
    brand_id,
  });
};

export const accFooterAboutUsEvent = async (path) => {
  fireEvent(events.select_about_us, {
    fire_screen: path,
  });
};

export const accViewReviewEvent = async (eventObj) => {
  fireEvent(events.view_review, {
    ...eventObj,
  });
};

export const accViewHomePageEvent = async (user_id) => {
  fireEvent(events.view_homepage, {
    user_id,
  });
};

export const accCategoryDropDownEvent = async (fire_screen) => {
  fireEvent(events.open_category_dropdown, {
    fire_screen,
  });
};

export const accPartnerBrandClickEvent = async (fire_screen, brand_name, scroll) => {
  fireEvent(events.select_partner_brand, {
    fire_screen,
    brand_name,
    scroll,
  });
};

export const accListingPageEvent = async (sub_category_name, sub_category_id, scroll) => {
  fireEvent(events.page_count, {
    sub_category_name,
    sub_category_id,
    scroll,
  });
};

export const accListingSortEvent = async (sub_category_name, sub_category_id, sort_by) => {
  fireEvent(events.sort_products, {
    sub_category_name,
    sub_category_id,
    sort_by,
  });
};

export const accProductShareEvent = async (product_id, segment_id, segment_name, category_name, category_id) => {
  fireEvent(events.share_button, {
    product_id,
    segment_id,
    segment_name,
    category_name,
    category_id,
  });
};

export const accEnterPincodeEvent = async (user_id) => {
  fireEvent(events.acc_enter_pincode, {
    user_id,
  });
};

export const accSubmitPincodeEvent = async (user_id, pincode) => {
  fireEvent(events.acc_pincode_submit, {
    user_id,
    pincode,
  });
};

export const accViewProductSpecificationEvent = async (user_id, product_id) => {
  fireEvent(events.view_specifications, {
    user_id,
    product_id,
  });
};

export const accProductTagClickEvent = async (user_id, product_id, popular_search_clicked) => {
  fireEvent(events.select_popular_searches, {
    user_id,
    product_id,
    popular_search_clicked,
  });
};

export const accViewAllProductOffersEvent = async (user_id, product_id) => {
  fireEvent(events.view_all_offers, {
    user_id,
    product_id,
  });
};

export const accViewAllProductReviewsEvent = async (user_id, product_id, rating, review_count) => {
  fireEvent(events.see_all_reviews, {
    user_id,
    product_id,
    rating,
    review_count,
  });
};

export const accNewCollectonCTopRailEvent = async (scroll, category_name, category_id, product_name, product_id) => {
  fireEvent(events.select_top_subcategory_collection, {
    scroll, category_name, category_id, product_name, product_id,
  });
};

export const accNewCollectonCBottomRailEvent = async (scroll, category_name, category_id, product_name, product_id) => {
  fireEvent(events.select_lower_category_collection, {
    scroll, category_name, category_id, product_name, product_id,
  });
};

export const accNewCollectonFilterClickEvent = async (filter_name) => {
  fireEvent(events.select_filter_collection, {
    filter_name,
  });
};

export const accNewCollectonVideoClickEvent = async (scroll) => {
  fireEvent(events.select_video_collection, {
    scroll,
  });
};

export const accViewNewCollectonPageEvent = async () => {
  fireEvent(events.view_collection_page, {});
};

export const accMarketingPageTopRailEvent = async (scroll, product_name, product_id, product_category, product_category_id, fire_screen) => {
  fireEvent(events.click_source_rail, {
    scroll, product_name, product_id, product_category, product_category_id, fire_screen,
  });
};

export const accMarketingPageAdjRailEvent = async (scroll, product_name, product_id, product_category, product_category_id, fire_screen) => {
  fireEvent(events.click_adj_cat_rail, {
    scroll, product_name, product_id, product_category, product_category_id, fire_screen,
  });
};

export const accMarketingPageLowerRailEvent = async (scroll, rail_name, rail_number, product_name, product_id) => {
  fireEvent(events.select_lower_marketing_collection, {
    scroll, rail_name, rail_number, product_name, product_id,
  });
};

export const accViewGoogleCollectonPageEvent = async (data) => {
  fireEvent(events.view_google_collection, {
    ...data,
  });
};

export const accProductReadMoreEvent = async (product_id, product_name, category_id, category_name) => {
  fireEvent(events.acc_prod_read_more, {
    product_id, product_name, category_id, category_name,
  });
};

export const accProductViewSimilarEvent = async (product_id, product_name, category_id, category_name) => {
  fireEvent(events.click_view_similar, {
    product_id, product_name, category_id, category_name,
  });
};

export const accProductViewWarrantyEvent = async (product_id, product_name, category_id, category_name, warranty_content) => {
  fireEvent(events.click_warranty_details, {
    product_id, product_name, category_id, category_name, warranty_content,
  });
};

export const accProductClickRatingTopEvent = async (product_id, product_name, category_id, category_name, review_count, rating_count) => {
  fireEvent(events.click_review_rating, {
    product_id, product_name, category_id, category_name, review_count, rating_count,
  });
};

export const accProductSizeEvent = async (product_id, product_name, category_id, category_name, size) => {
  fireEvent(events.select_product_size, {
    product_id, product_name, category_id, category_name, size,
  });
};

export const accProductOpenFaqEvent = async (product_id, product_name, category_id, category_name) => {
  fireEvent(events.open_faq_section, {
    product_id, product_name, category_id, category_name,
  });
};

export const accProductOpenReviewEvent = async (product_id, product_name, category_id, category_name, review_count, rating_count) => {
  fireEvent(events.open_review_rating_section, {
    product_id, product_name, category_id, category_name, review_count, rating_count,
  });
};

export const accProductViewVideoEvent = async (product_id, product_name, category_id, category_name, scroll) => {
  fireEvent(events.view_product_video, {
    product_id, product_name, category_id, category_name, scroll,
  });
};

export const accTapFAQGtmEvent = async (scroll, fire_screen) => {
  fireEvent(events.acc_faq, {
    scroll, fire_screen,
  });
};

export const accViewSubCategoryPage = async (user_id, sub_category, sub_category_id) => {
  fireEvent(events.view_sub_category, {
    user_id, sub_category, sub_category_id,
  });
};

export const accViewProductPageImage = async (user_id, product_id, segment_name, category_name, scroll) => {
  fireEvent(events.view_product_image, {
    user_id, product_id, segment_name, category_name, scroll,
  });
};

export const accAddQtyCartEvent = async (user_id, segment_name, final_amount) => {
  fireEvent(events.acc_add_more, {
    user_id, segment_name, final_amount,
  });
};

export const accRemoveQtyCartEvent = async (user_id, segment_name, final_amount) => {
  fireEvent(events.acc_remove_quantity, {
    user_id, segment_name, final_amount,
  });
};

export const accViewBillPopUpEvent = async (user_id, total_amount, coupon_code) => {
  fireEvent(events.view_bill, {
    user_id, total_amount, coupon_code,
  });
};

export const accClickMagnifierGtmEvent = async (search_term) => {
  fireEvent(events.click_magnifier_icon, {
    search_term,
  });
};

export const accViewBrandPageEvent = async (brand_name, user_id) => {
  fireEvent(events.view_brand_page, {
    brand_name, user_id,
  });
};

export const accSelectBrandCategoryEvent = async (brand_name, category_name) => {
  fireEvent(events.select_sub_category_brand_page, {
    brand_name, category_name,
  });
};

export const accSelectBrandQuickFilterEvent = async (brand_name, quick_filter_name) => {
  fireEvent(events.select_quick_filter_brand_page, {
    brand_name, quick_filter_name,
  });
};

export const accSelectExportCategoryEvent = async (category_name, category_id, user_id) => {
  fireEvent(events.select_export_category, {
    category_name, category_id, user_id,
  });
};

export const accSelectFormColumnEvent = async (column_name, user_id) => {
  fireEvent(events.contact_us_detail, {
    column_name, user_id,
  });
};

export const accExportSubmitDetailsEvent = async (name, email, country, contact_number, categories, distributor) => {
  fireEvent(events.submit_details, {
    name, email, country, contact_number, categories, distributor,
  });
};

export const accExportViewGalleryEvent = async (gallery_name, scroll) => {
  fireEvent(events.view_gallery, {
    gallery_name, scroll,
  });
};

export const accClickExportContactDetailsEvent = async (details) => {
  fireEvent(events.click_gom_export_details, {
    details,
  });
};

export const accClickExportMapEvent = async () => {
  fireEvent(events.click_export_map, {});
};

export const accClickExportReadMoreEvent = async () => {
  fireEvent(events.export_read_more, {});
};

export const retailInitiateLogin = async (fire_screen) => {
  fireEvent(events.web_init_login, {
    fire_screen,
  });
};

export const retailGetOtpEvent = async (fire_screen, phone_number) => {
  fireEvent(events.web_get_otp, {
    fire_screen, phone_number,
  });
};

export const retailLoginEvent = async (user_id, fire_screen, phone_number, success, truecaller) => {
  fireEvent(events.web_login, {
    user_id, fire_screen, phone_number, success, truecaller,
  });
};

export const retailSelectCarEvent = async (user_id, fire_screen) => {
  fireEvent(events.web_tap_select_car, {
    user_id, fire_screen,
  });
};

export const selectCarFuelGtmEvent = async (user_id, fire_screen, fuel_type, car_name, car_id, brand_name, brand_id) => {
  fireEvent(events.web_select_fuel_type, {
    user_id, fire_screen, fuel_type, car_name, car_id, brand_name, brand_id,
  });
};

export const retailBannerClickEvent = async (user_id, fire_screen, car_id, banner_name, banner_id, scroll, banner_position) => {
  fireEvent(events.web_click_banner, {
    user_id, fire_screen, car_id, banner_name, banner_id, scroll, banner_position,
  });
};

export const luxuryAddToCartGtmEvent = async (
  user_id, fire_screen, item_name, item_id, category, sub_category, miles, car_id, boosters,
) => {
  fireEvent(events.web_luxury_atc, {
    user_id, fire_screen, item_name, item_id, category, sub_category, miles, car_id, boosters,
  });
};

export const openSelectCityModalEvent = async (user_id, current_city) => {
  fireEvent(events.web_click_city, {
    user_id, current_city,
  });
};

export const detectCityEvent = async (user_id, current_city, new_city) => {
  fireEvent(events.web_click_detect_location, {
    user_id, current_city, new_city,
  });
};

export const changeCityEvent = async (user_id, current_city, new_city) => {
  fireEvent(events.web_change_city, {
    user_id, current_city, new_city,
  });
};

export const tapApplyCouponEvent = async (user_id, miles, car_id) => {
  fireEvent(events.web_tap_apply_coupon, {
    user_id, miles, car_id,
  });
};

export const applyGoAppEvent = async (user_id, cart_value, go_app_amount, car_id, miles) => {
  fireEvent(events.web_apply_goapp, {
    user_id, cart_value, go_app_amount, car_id, miles,
  });
};

export const selectAddressEvent = async (user_id, car_id, address_id) => {
  fireEvent(events.web_select_address, {
    user_id, car_id, address_id,
  });
};

export const tapDownloadFromStoreAppEvent = async (user_id, store_type, car_id) => {
  fireEvent(events.web_open_app_store, {
    user_id, store_type, car_id,
  });
};

export const getAppLinkEvent = async (user_id, phone_number, car_id) => {
  fireEvent(events.web_get_app_link, {
    user_id, phone_number, car_id,
  });
};

export const viewHomePageEvent = async (user_id, car_id) => {
  fireEvent(events.view_homepage, {
    user_id, car_id,
  });
};

export const viewMilesPageEvent = async (user_id, car_id) => {
  fireEvent(events.web_view_miles, {
    user_id, car_id,
  });
};

export const viewWarrantyPageEvent = async (user_id, car_id) => {
  fireEvent(events.web_view_warranty, {
    user_id, car_id,
  });
};

export const addMilesToCartEvent = async (fire_screen, user_id, with_sos, car_id) => {
  fireEvent(events.web_miles_to_cart, {
    fire_screen, user_id, with_sos, car_id,
  });
};

export const initiateAtcEvent = async (user_id, fire_screen, item_name, item_id, category, miles, car_id) => {
  fireEvent(events.web_init_atc, {
    user_id, fire_screen, item_name, item_id, category, miles, car_id,
  });
};

export const clickCallBtnEvent = async (user_id, car_id, fire_screen, miles) => {
  fireEvent(events.web_click_call_button, {
    user_id, car_id, fire_screen, miles,
  });
};

export const clickCategoryBtnEvent = async (user_id, fire_screen, category, category_id, miles, car_id) => {
  fireEvent(events.web_click_cat, {
    user_id, fire_screen, category, category_id, miles, car_id,
  });
};

export const clickServiceItemBtnEvent = async (user_id, fire_screen, item_name, item_id, category, sub_category, miles, car_id) => {
  fireEvent(events.web_click_item, {
    user_id, fire_screen, item_name, item_id, category, sub_category, miles, car_id,
  });
};

export const clickFaqEvent = async (user_id, car_id, miles, fire_screen, faq_index) => {
  fireEvent(events.web_click_faq, {
    user_id, car_id, miles, fire_screen, faq_index,
  });
};

export const removeCouponEvent = async (user_id, coupon_name, coupon_type, car_id) => {
  fireEvent(events.web_remove_coupon, {
    user_id, coupon_name, coupon_type, car_id,
  });
};

export const copyCouponToClipboardEvent = async (user_id, coupon_name, coupon_type, car_id, miles, fire_screen) => {
  fireEvent(events.web_click_coupon, {
    user_id, coupon_name, coupon_type, car_id, miles, fire_screen,
  });
};
