import { SET_SEGMENT_PAGE_DETAILS } from '../constants';

const segmentPageDetails = (state = {}, action) => {
  switch (action.type) {
    case SET_SEGMENT_PAGE_DETAILS:
      return action.payload.value;
    default:
      return state;
  }
};


export default segmentPageDetails;
