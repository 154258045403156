import HttpHeader from '../httpHeader';
import { Http } from '../../modules/http';

export const fetchAllBrands = () => {
  const Headers = new HttpHeader();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `/api/v1/get-brands`;
  return HttpHandler.get(url, headers);
};
