import { SET_CART_BOOSTER_DATA } from '../constants';

export default function cartBoosters(state = null, action) {
  switch (action.type) {
    case SET_CART_BOOSTER_DATA:
      return action.payload.value;
    default:
      return state;
  }
}
