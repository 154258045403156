import { SET_SEO_SERVICE_DETAILS_DATA } from '../constants';

export default function seoServiceDetails(state = null, action) {
  switch (action.type) {
    case SET_SEO_SERVICE_DETAILS_DATA:
      return action.payload.value;
    default:
      return state;
  }
}
