import { SET_USER_ACCESSORIES_CART } from '../constants';

export default function setUserAccessoriesCart(state = {}, action) {
  switch (action.type) {
    case SET_USER_ACCESSORIES_CART: {
      return action.payload.value;
    }
    default:
      return state;
  }
}
