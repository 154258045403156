export const descriptionData = {
  city: 'Book General Car Service, Car Wheel Repair, Car Denting, Car Painting, Car Wash Services Online. Best Service Centers in <City>',
  'city_service-car-repair': 'Book Scheduled Car Service and Repair at Best Service Centers and Garages in <City> with Free Pickup and Drop and Trained Mechanics.',
  'city_service-sos-service': 'Need Battery Jumpstart, Towing or Roadside Assistance? Book Affordable Car SOS Services in <City> with GoMechanic car workshops.',
  'city_service-car-ac-repair': 'Book Car AC Service and Repair at Best Service Centers and Garages in <City> with Free Pickup and Drop. Trained Mechanics, Equipped Workshops. Book Now.',
  'city_service-car-tyre-replacement': 'Looking for Car Tyres & Wheel Care Services? Book Tyre Replacement Services from brands like CEAT, JK, MRF, etc with up to 40% savings at the Best Car Garages in <City>.',
  'city_service-car-cleaning-service': 'Book Car Cleaning and Washing at Best Service Centers and Garages near you in <City>. Rubbing Polishing, Ceramic Coating, Dry Cleaning, all services available.',
  'city_service-car-dent-paint': 'Book Car Denting and Painting Service at Best Paint Booths and Garages with 100% Colour Match Guarantee and 2 Years Warranty in <City>',
  'city_service-car-battery-replacement': 'Book Affordable Car Battery Replacement & Jumpstart Services for your Car at the Best Car Garages in <City>. Batteries from Amaron, Exide and Livguard, etc available.',
  'city_service-car-insurance': 'Book Car Insurance & Towing Services at Cashless Service Centers and Car Garages in <City>. Tata AIG, Acko, HDFC Ergo, and IFFCO Tokio, all Claims Processed.',
  'city_service-custom-repair': "Facing Issues With Your Car? Now Avail Top-Notch Car Inspection Service And Know More About Your Car's Health By Our Experts in <City>",
  'city_service-car-service-center': 'Book Car Wheel Care, Tyre Replacement and Wheel Alignment at Best Service Centers and Garages in <City>. Trained Mechanics, State-of-the-art Equipments, Book Now!',
  'city_service-car-lights-replacement':'Book Car Clutch & Body Parts Replacement in <City> by the Best Car Mechanics And Get Free Pickup & Drop.',
  'city_service-car-accessories':'Choose from Best Car Accessories in <City>. Get Best Quailty Car Air Purifier, Wireless Charger, Car Vaccum Cleaner, Tyre Inflator and more from GoMechanic.',
  'city_service-car-detailing':'Looking for Car Detailing Services in <City>? Choose from PPF, Ceramic Coating, Teflon Coating and much more with GoMechanic and enjoy Free Pickup and Drop.',
  'city_service-windshield-glass-replacement':'Need Windshield & Car Door Glass Replacement in <City>? Get High-Quality Front Rear Windshield & Door Glass Replacement Services with Free Pickup & Drop.',
  'city_service-car-suspension-fitment':'Need Car Suspension & Fitments Service in <City>? Now, Get The Best-in-Class Car Suspension & Fitments Service by Leading Car Experts',
  'city_service-car-car-repair': 'Book <Car> Scheduled Car Service and Repair at Best Service Centers and Garages in <City> with Free Pickup and Drop and Trained Mechanics.',
  'city_service-car-sos-service': 'Need Battery Jumpstart, Towing or Roadside Assistance? Book Affordable <Car> Car SOS Services in <City> with GoMechanic car workshops.',
  'city_service-car-car-ac-repair': 'Book <Car> Car AC Service and Repair at Best Service Centers and Garages in <City> with Free Pickup and Drop. Trained Mechanics, Equipped Workshops. Book Now.',
  'city_service-car-car-tyre-replacement': 'Looking for <Car> Car Tyres & Wheel Care Services? Book Tyre Replacement Services from brands like CEAT, JK, MRF, etc with up to 40% savings at the Best Car Garages in <City>.',
  'city_service-car-car-cleaning-service': 'Book <Car> Car Cleaning and Washing at Best Service Centers and Garages near you in <City>. Rubbing Polishing, Ceramic Coating, Dry Cleaning, all services available.',
  'city_service-car-car-dent-paint': 'Book <Car> Car Denting and Painting Service at Best Paint Booths and Garages with 100% Colour Match Guarantee and 2 Years Warranty in <City>.',
  'city_service-car-car-battery-replacement': 'Book Affordable Car Battery Replacement & Jumpstart Services for your <Car> Car at the Best Car Garages in <City>. Batteries from Amaron, Exide and Livguard, etc available.',
  'city_service-car-car-insurance': 'Book <Car> Car Insurance & Towing Services at Cashless Service Centers and Car Garages in <City>. Tata AIG, Acko, HDFC Ergo, and IFFCO Tokio, all Claims Processed.',
  'city_service-car-custom-repair': "Facing Issues With Your Car? Now Avail Top-Notch <Car> Car Inspection Service And Know More About Your Car's Health By Our Experts in <City>",
  'city_service-car-car-service-center': 'Book <Car> Car Wheel Care, Tyre Replacement and Wheel Alignment at Best Service Centers and Garages in <City>. Trained Mechanics, State-of-the-art Equipments, Book Now!',
  'city_service-car-car-lights-replacement':'Book <Car> Car Clutch & Body Parts Replacement in <City> by the Best Car Mechanics And Get Free Pickup & Drop.',
  'city_service-car-car-accessories':'Choose from Best <Car> Car Accessories in <City>. Get Best Quailty Car Air Purifier, Wireless Charger, Car Vaccum Cleaner, Tyre Inflator and more from GoMechanic.',
  'city_service-car-car-detailing':'Looking for <Car> Car Detailing Services in <City>? Choose from PPF, Ceramic Coating, Teflon Coating and much more with GoMechanic and enjoy Free Pickup and Drop.',
  'city_service-car-windshield-glass-replacement':'Need <Car> Windshield & Car Door Glass Replacement in <City>? Get High-Quality Front Rear Windshield & Door Glass Replacement Services with Free Pickup & Drop.',
  'city_service-car-car-suspension-fitment':'Need <Car> Suspension & Fitments Service in <City>? Now, Get The Best-in-Class Car Suspension & Fitments Service by Leading Car Experts'
};

export const mlyDescriptionData = {
  city: 'Book General Car Service, Car Wheel Repair, Car Denting, Car Painting, Car Wash Services Online. Best Service Centers in <City>',
  'city_service-car-repair': 'Book Periodic Service for your Car by trained car mechanics at Best Car Workshops in <City>.',
  'city_service-car-ac-repair': 'Book Car Aircon AC Service and Repair at Best Service Centers and Garages in <City> with Free Pickup and Drop. Best Mechanics, Equipped Workshops. Book Now.',
  'city_service-car-tyre-replacement': 'Looking for Car Tyres? Book Tyres from brands such as Apollo, CEAT, JK, MRF, Bridgestone etc with 30% savings in <City>. National Best Price Guaranteed.',
  'city_service-car-cleaning-service': 'Book Car Cleaning and Washing at Best Service Centers and Garages near you in <City>. Rubbing Polishing, Ceramic Coating, Dry Cleaning, all services available.',
  'city_service-car-dent-paint': 'Book Car Denting and Painting Service at Best Paint Booths and Garages with 100% Colour Match Guarantee and 2 Years Warranty in <City>',
  'city_service-car-battery-replacement': 'Looking for Car Batteries ? Book Batteries from Brands such as Exide, Amaron and SF Sonic with 30% savings in <City>. National Best Prices Guaranteed.',
  'city_service-car-insurance': 'Book Car Insurance Service and Repairs Claims at Cashless Service Centers and Garages in <City>. Tata AIG, Acko, HDFC Ergo, Iffco Tokyo, all Claims Processed.',
  'city_service-custom-repair': "Facing Issues With Your Car? Now Avail Top-Notch Car Inspection Service And Know More About Your Car's Health By Our Experts in <City>",
  'city_service-car-service-center': 'Book Car Wheel Care, Tyre Replacement and Wheel Alignment at Best Service Centers and Garages in <City>. Trained Mechanics, State-of-the-art Equipments, Book Now!',
  'city_service-car-lights-replacement':'Book Car Clutch & Bumper Replacement in <City> by Experienced Professionals And Get Free Pickup & Drop',
  'city_service-car-accessories':'Choose from Best Car Accessories in <City>. Get Best Quailty Car Air Purifier, Wireless Charger, Car Vaccum Cleaner, Tyre Inflator and more from GoMechanic.',
  'city_service-car-detailing':'Looking for Car Detailing Services in <City>? Choose from PPF, Ceramic Coating, Teflon Coating and much more with GoMechanic and enjoy Free Pickup and Drop.',
  'city_service-windshield-glass-replacement':"Book Best Windshields & Lights Services for your Car by the trained mechanics at the best car service centres in <City>.",
  'city_service-car-suspension-fitment':"Need Car Suspension & Bumpers Service in <City>? Try GoMechanic to get the Best Service for Car at Malaysia's best car workshops.",
  'city_service-car-car-repair': 'Book Periodic Service for your <Car> Car by trained car mechanics at Best Car Workshops in <City>.',
  'city_service-car-car-ac-repair': 'Book <Car> Car Aircon AC Service and Repair at Best Service Centers and Garages in <City> with Free Pickup and Drop. Best Mechanics, Equipped Workshops. Book Now.',
  'city_service-car-car-tyre-replacement': 'Looking for <Car> Car Tyres? Book Tyres from brands such as Apollo, CEAT, JK, MRF, Bridgestone etc with 30% savings in <City>. National Best Price Guaranteed.',
  'city_service-car-car-cleaning-service': 'Book <Car> Car Cleaning and Washing at Best Service Centers and Garages near you in <City>. Rubbing Polishing, Ceramic Coating, Dry Cleaning, all services available.',
  'city_service-car-car-dent-paint': 'Book <Car> Car Denting and Painting Service at Best Paint Booths and Garages with 100% Colour Match Guarantee and 2 Years Warranty in <City>.',
  'city_service-car-car-battery-replacement': 'Looking for <Car> Car Batteries ? Book Batteries from Brands such as Exide, Amaron and SF Sonic with 30% savings in <City>. National Best Prices Guaranteed.',
  'city_service-car-car-insurance': 'Book <Car> Car Insurance Service and Repairs Claims at Cashless Service Centers and Garages in <City>. Tata AIG, Acko, HDFC Ergo, Iffco Tokyo, all Claims Processed.',
  'city_service-car-custom-repair': "Facing Issues With Your Car? Now Avail Top-Notch <Car> Car Inspection Service And Know More About Your Car's Health By Our Experts in <City>",
  'city_service-car-car-service-center': 'Book <Car> Car Wheel Care, Tyre Replacement and Wheel Alignment at Best Service Centers and Garages in <City>. Trained Mechanics, State-of-the-art Equipments, Book Now!',
  'city_service-car-car-lights-replacement':'Book <Car> Car Clutch & Bumper Replacement in <City> by Experienced Professionals And Get Free Pickup & Drop',
  'city_service-car-car-accessories':'Choose from Best <Car> Car Accessories in <City>. Get Best Quailty Car Air Purifier, Wireless Charger, Car Vaccum Cleaner, Tyre Inflator and more from GoMechanic.',
  'city_service-car-car-detailing':'Looking for <Car> Car Detailing Services in <City>? Choose from PPF, Ceramic Coating, Teflon Coating and much more with GoMechanic and enjoy Free Pickup and Drop.',
  'city_service-car-windshield-glass-replacement':'Book Best Windshields & Lights Services for your <Car> Car by the trained mechanics at the best car service centres in <City>.',
  'city_service-car-car-suspension-fitment':"Need Car Suspension & Bumpers Service in <City>? Try GoMechanic to get the Best Service for <Car> Car at Malaysia's best car workshops."
};
