/* eslint-disable import/prefer-default-export */
import { SET_WARRANTY_CARD } from '../constants';

export function setWarrantyCard(value) {
  return {
    type: SET_WARRANTY_CARD,
    payload: {
      value,
    },
  };
}
