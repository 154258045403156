import configureStore from './store/configureStore';

const storeExport = {
  store: null,
};
export const initializeStore = (initialState, history) => {
  let newIntitialState = initialState;
  // try {
  //   if (initialState.cardetails === null || initialState.cardetails.brand === null) {
  //     const storageBrowser = new StorageBrowser();
  //     console.log('in try');
  //     const cardetailsFromLS = storageBrowser.getStorageLS('cardetails');
  //     if (cardetailsFromLS) {
  //       newIntitialState = { ...initialState, cardetails: cardetailsFromLS };
  //     }
  //   }
  // } catch (e) {
  //   console.error(e);
  // }

  storeExport.store = configureStore(newIntitialState, {
    history,
  });
  return storeExport.store;
};
export default storeExport;
