import { Http } from '../modules/http';
import Global from '../global';
import TokenHeader from './TokenHeader';


export default class AuthService extends TokenHeader {
  http = new Http();

  global = new Global();

  login(data, token) {
    const header = get_token_headers(token);
    const url = '';
    const data1 = data;
    return this.http.post(url, data1, header);
  }

  fetchToken() {
    const headers = this.get_headers();
    const url = `${this.global.urls.api_get}get_session?source=Website`;
    return this.http.get(url, headers);
  }

  sendOTP(data, token) {
    const header = this.get_token_headers(token);
    const url = `${this.global.urls.api_get}send_otp/`;
    return this.http.post(url, data, header);
  }

  verifyLogin(data, token) {
    const header = this.get_token_headers(token);
    const url = `${this.global.urls.api_get}verify_login`;
    return this.http.post(url, data, header);
  }

  userDetails(data, token) {
    const header = this.get_token_headers(token);
    const url = `${this.global.urls.api_get}verify_ref_code/`;
    return this.http.post(url, data, header);
  }

  get_headers() {
    return {
      'Content-Type': 'application/json',
    };
  }
}
