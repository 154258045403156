import HttpHeader from '../../httpHeader';
import Global from '../../../global';
import { Http } from '../../../modules/http';

export const getProductDetails = (productID, carParam) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}products/${productID}/${
    carParam ? `?${carParam}` : ''
  }`;
  return HttpHandler.get(url, headers);
};

export const getSimilarProducts = (productID, pageParam) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}similar-products/${productID}/${pageParam ? `?${pageParam}` : ''}`;
  return HttpHandler.get(url, headers);
};

export const getRecommendedProducts = (productID, pageParam) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}recommendations/?product_id=${productID}${pageParam ? `&${pageParam}` : ''}`;
  return HttpHandler.get(url, headers);
};

export const getLikedProducts = (productID, pageParam) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}products/?customer_liked=1${pageParam ? `&${pageParam}` : ''}${productID ? `&customer_liked_product_id=${productID}` : ''}`;
  return HttpHandler.get(url, headers);
};
export const checkDeliveryAvailability = (pin) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}delivery-time-check/?pin_code=${pin}`;
  return HttpHandler.get(url, headers);
};

export const savedWarranty = (warrantyParam) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  delete headers['Content-Type'];
  const url = `${ApiManager.urls.accessories_url}warranty-registration/`;
  return HttpHandler.post(url, warrantyParam, headers);
};

export const getProductID = () => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}warranty-registration/`;
  return HttpHandler.get(url, headers);
};

export const getProductRails = (productId, name, productCount) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}rails/?${productId ? `product_id=${productId}` : ''}${name ? `&name=${name}` : ''}${productCount ? `&products_limit=${productCount}` : ''}`;
  return HttpHandler.get(url, headers);
};

export const notifyMeApi = (data) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}notify-me/`;
  return HttpHandler.post(
    url,
    {
      ...data,
    },
    headers,
  );
};

export const addRecentlyViewed = (skucode) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}recently-viewed-products/`;
  return HttpHandler.post(
    url,
    {
      sku_code: skucode,
    },
    headers,
  );
};

export const getRecentlyViewedProducts = () => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}recently-viewed-products/`;
  return HttpHandler.get(url, headers);
};

export const getProductsReviews = (productId, page, count) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}website-reviews/${productId}?page=${page}&page_size=${count}`;
  return HttpHandler.get(url, headers);
};
