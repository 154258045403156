import { SET_USER_ACCESSORIES_CART } from '../constants';

export function setUserAccessoriesCart(value) {
  return {
    type: SET_USER_ACCESSORIES_CART,
    payload: {
      value,
    },
  };
}
