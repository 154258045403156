import { SET_AMC_USER } from '../constants';

export function isAmcUser (value) {
  return {
    type: SET_AMC_USER,
    payload: {
      value,
    },
  };
}
