import HttpHeader from '../httpHeader';
import { Http } from '../../modules/http';

export const getCityCarousels = (city='delhi') => {
  const Headers = new HttpHeader();
  const HttpHandler = new Http();

  let path = '';
  if (typeof window !== 'undefined') {
    path = window.location.pathname;
  }
  const headers = Headers.get_token_headers();
  const url = `/api/v1/get-city-carousels?city=${city}`;
  return HttpHandler.get(url, headers);
};
