export const titleData = {
  city: 'Best Car Service | Car Repair | Car Cleaning in <City> - GoMechanic',
  'city_service-car-repair': 'Best Car Service and Mechanic Repair in <City> - GoMechanic',
  'city_service-sos-service': 'Car Roadside Assistance | SOS Services in <City> - GoMechanic',
  'city_service-car-ac-repair': 'Best Car AC Service and Repair in <City> - GoMechanic',
  'city_service-car-tyre-replacement': 'Best Tyre Replacement & Wheel Care Services in <City> - GoMechanic',
  'city_service-car-cleaning-service': 'Best Car Cleaning Service in <City> - GoMechanic',
  'city_service-car-dent-paint': 'Best Car Denting Painting Service in <City> - GoMechanic',
  'city_service-car-battery-replacement': 'Best Car Battery Replacement & Jumpstart in <City> - GoMechanic',
  'city_service-car-insurance': 'Best Car Insurance & Towing Services in <City> - GoMechanic',
  'city_service-custom-repair': 'Car Inspection Service in <City> - GoMechanic',
  'city_service-car-service-center': 'Best Car Service Center Near Me in <City> - GoMechanic',
  'city_service-car-lights-replacement':'Clutch & Body Parts Replacement in <City> - GoMechanic',
  'city_service-car-accessories':'Best Car Accessories in <City> - GoMechanic',
  'city_service-car-detailing':'Best Car Detailing Services in <City> - GoMechanic',
  'city_service-windshield-glass-replacement':'Car Windshield & Glass Replacement in <City> - GoMechanic',
  'city_service-car-suspension-fitment':'Suspension & Fitments Repair in <City> - GoMechanic',
  'city_service-car-car-repair': 'Best <Car> Car Service and Mechanic Repair in <City> - GoMechanic',
  'city_service-car-sos-service': '<Car> Car Roadside Assistance | SOS Services in <City> - GoMechanic',
  'city_service-car-car-ac-repair': 'Best <Car> Car AC Service and Repair in <City> - GoMechanic',
  'city_service-car-car-tyre-replacement': 'Best <Car> Car Tyre Replacement & Wheel Care Services in <City> - GoMechanic',
  'city_service-car-car-cleaning-service': 'Best <Car> Car Cleaning Service in <City> - GoMechanic',
  'city_service-car-car-dent-paint': 'Best <Car> Car Denting Painting Service in <City> - GoMechanic',
  'city_service-car-car-battery-replacement': 'Best <Car> Car Battery Replacement & Jumpstart in <City> - GoMechanic',
  'city_service-car-car-insurance': 'Best <Car> Car Insurance & Towing Services in <City> - GoMechanic',
  'city_service-car-custom-repair': '<Car> Car Inspection Service in <City> - GoMechanic',
  'city_service-car-car-service-center': 'Best <Car> Car Service Center Near Me in <City> - GoMechanic',
  'city_service-car-car-lights-replacement':'<Car> Car Clutch & Body Parts Replacement in <City> - GoMechanic',
  'city_service-car-car-detailing':'Best <Car> Car Detailing Services in <City> - GoMechanic',
  'city_service-car-car-accessories':'Best <Car> Car Accessories in  <City> - GoMechanic',
  'city_service-car-windshield-glass-replacement':'<Car> Car Windshield & Glass Replacement in <City> - GoMechanic',
  'city_service-car-car-suspension-fitment':'<Car> Car Suspension & Fitments Repair in <City> - GoMechanic',
};

export const mlyTitleData = {
  city: 'Best Car Service | Car Repair | Car Cleaning in <City> - GoMechanic',
  'city_service-car-repair': '25+ Best Car Workshops in <City> | Periodic Service - GoMechanic',
  'city_service-car-ac-repair': 'Best Car Aircon Service in <City> - GoMechanic',
  'city_service-car-tyre-replacement': 'Best Car Tyre Replacement in <City> - GoMechanic',
  'city_service-car-cleaning-service': 'Best Car Cleaning Service in <City> - GoMechanic',
  'city_service-car-dent-paint': 'Best Car Denting Painting Service in <City> - GoMechanic',
  'city_service-car-battery-replacement': 'Best Car Battery Replacement in <City> - GoMechanic',
  'city_service-car-insurance': 'Best Car Insurance Claim and Garage in <City> - GoMechanic',
  'city_service-custom-repair': 'Car Inspection Service in <City> - GoMechanic',
  'city_service-car-service-center': 'Best Car Service Center Near Me in <City> - GoMechanic',
  'city_service-car-lights-replacement':'Clutch & Bumper Replacement in <City> - GoMechanic',
  'city_service-car-accessories':'Best Car Accessories in <City> - GoMechanic',
  'city_service-car-detailing':'Best Car Detailing Services in <City> - GoMechanic',
  'city_service-windshield-glass-replacement':'Best Car Windshields and Lights Services in <City> - GoMechanic',
  'city_service-car-suspension-fitment':'Best Car Bumpers and Suspension Service in <City> - GoMechanic',
  'city_service-car-car-repair': '25+ Best <Car> Car Workshops in <City> | Periodic Service - GoMechanic',
  'city_service-car-car-ac-repair': 'Best <Car> Car Aircon Service in <City> - GoMechanic',
  'city_service-car-car-tyre-replacement': 'Best <Car> Car Tyre Replacement in <City> - GoMechanic',
  'city_service-car-car-cleaning-service': 'Best <Car> Car Cleaning Service in <City> - GoMechanic',
  'city_service-car-car-dent-paint': 'Best <Car> Car Denting Painting Service in <City> - GoMechanic',
  'city_service-car-car-battery-replacement': 'Best <Car> Car Battery Replacement in <City> - GoMechanic',
  'city_service-car-car-insurance': 'Best <Car> Car Insurance Claim and Garage in <City> - GoMechanic',
  'city_service-car-custom-repair': '<Car> Car Inspection Service in <City> - GoMechanic',
  'city_service-car-car-service-center': 'Best <Car> Car Service Center Near Me in <City> - GoMechanic',
  'city_service-car-car-lights-replacement':'<Car> Car Clutch & Bumper Replacement in <City> - GoMechanic',
  'city_service-car-car-detailing':'Best <Car> Car Detailing Services in <City> - GoMechanic',
  'city_service-car-car-accessories':'Best <Car> Car Accessories in  <City> - GoMechanic',
  'city_service-car-windshield-glass-replacement':'Best <Car> Car Windshields and Lights Services in <City> - GoMechanic',
  'city_service-car-car-suspension-fitment':'Best <Car> Car Bumpers and Suspension Service in <City> - GoMechanic',
};
