import { SET_APPLIED_COUPON } from '../constants';

export function setCoupon(value) {
  return {
    type: SET_APPLIED_COUPON,
    payload: {
      value,
    },
  };
}
