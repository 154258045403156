import { SET_ACCESSORIES_ENQUIRY_DATA } from '../constants';

const accessoriesEnquiryData = (state = {}, action) => {
  switch (action.type) {
    case SET_ACCESSORIES_ENQUIRY_DATA:
      return action.payload.value;
    default:
      return state;
  }
};


export default accessoriesEnquiryData;