import { SET_SELECTED_ADDR_ID } from '../constants';

export default function selectedAddressId(state = null, action) {
  switch (action.type) {
    case SET_SELECTED_ADDR_ID: {
      return action.payload.value;
    }
    default:
      return state;
  }
}
