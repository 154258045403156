import { SET_CAR_FOR_CART } from '../constants';

export function setCarForCart(value) {
  return {
    type: SET_CAR_FOR_CART,
    payload: {
      value,
    },
  };
}
