import { SET_POPULAR_BRANDS } from "../constants";

export default function popularBrands(state = null, action) {
    switch (action.type) {
      case SET_POPULAR_BRANDS:
        return action.payload.value;
      default:
        return state;
    }
  }
  