import { getCartTotal, priceCalculator } from './_services/cartServices';
import storeExport from './clientStore';
import { setCarForCart } from './actions/setCarForCart';
import { setCoupon } from './actions/setCoupon';
import { setCartResolved } from './actions/setCartResolved';
import { setAddOnServices } from './actions/addOnServices';
import Global from './global';

export const accessoriesPageTypes = {
  home: 'home',
  products: 'products',
  categories: 'categories',
  segment: 'segment',
};

export const isServer = () => typeof window === 'undefined';

const cartApiKeysToSend = ['id', 'issues', 'count', 'type', 'brand', 'miles_without_sos', 'size'];


export const isNigerianRequest = () => {
  let result = false;
  if (isServer()) {
    try {
      if (process.env.forceNigeria && process.env.forceNigeria === 'true') result = true;
    } catch (e) {
      console.error(e);
    }
  } else {
    const ApiManager = new Global();
    if (ApiManager.urls.forceNigeria) result = true;
  }
  return result;
};

export const isMalaysiaRequest = () => {
  let result = false;
  if (isServer()) {
    try {
      if (process.env.forceMalaysia && process.env.forceMalaysia === 'true') result = true;
    } catch (e) {
      console.error(e);
    }
  } else {
    const ApiManager = new Global();
    if (ApiManager.urls.forceMalaysia) result = true;
  }
  return result;
};

export const dayNames = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
export const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];


export const availableTimeSlotsForDate = (timeSlots, compareDateString) => timeSlots.filter((timeSlot) => {
  const currentDate = new Date();
  const currentDateString = `${currentDate.getFullYear()}-${`0${currentDate.getMonth()
      + 1}`.slice(-2)}-${`0${currentDate.getDate()}`.slice(-2)}`;
  if (currentDateString === compareDateString) {
    const compareTime = new Date(new Date().setHours(timeSlot.startHour));
    const difference = compareTime - new Date();
    const diffInMinutes = Math.round(difference / 1000 / 60);
    return diffInMinutes > 120;
  }
  return true;
});

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const isPhonePeBrowser = () => {
  if (isServer()) {
    try {
      if (process.env.forcePhonepe && process.env.forcePhonepe === 'true') return true;
    } catch (e) {
      console.error(e);
    }
  }
  if (typeof navigator !== 'undefined' && navigator.userAgent) {
    if (navigator.userAgent.indexOf('phonepe') !== -1) {
      return true;
    }
  }
  return false;
};

export const isPaytmBrowser = () => {
  if (typeof navigator !== 'undefined' && navigator.userAgent) {
    if (navigator.userAgent.indexOf('AppContainer') !== -1) {
      return true;
    }
  }
  return false;
};

export const getQueryStringValue = (key, url = false) => {
  if (typeof window === 'undefined' || typeof window.location === 'undefined') return null;
  return decodeURIComponent((url || window.location.search).replace(new RegExp(`^(?:.*[&\\?]${encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&')}(?:\\=([^&]*))?)?.*$`, 'i'), '$1'));
};

export const slugify = (string) => string.toLowerCase().replace(/\s+/g, '-');

export const cartObjAfterAdding = (service) => {
  const props = storeExport.store.getState();

  let servicesFiltered = null;

  const { cartRules } = props;
  if (cartRules) {
    // debugger;

    for (let i = 0; i < cartRules.length; i++) {
      const rule = cartRules[i];

      // debugger;

      if (rule.type === cartRules.SAME_SERVICE_TYPE_SINGLE_SELECT_SERVICE) {
        const serviceTypeId = rule.retail_service_type_id;
        if (
          serviceTypeId
          === (service.parent_id || service.retail_service_type_id)
        ) {
          servicesFiltered = props.cart.filter(
            (item) => serviceTypeId !== (item.parent_id || item.retail_service_type_id),
          );
          // debugger;
        }
        // debugger;
      }

      if (rule.type === cartRules.SAME_SERVICE_TYPE_ALL_INCLUSIVE_SERVICE) {
        const serviceTypeId = rule.retail_service_type_id;
        if (
          serviceTypeId
          === (service.parent_id || service.retail_service_type_id)
        ) {
          // if all inclusive service is being addded
          if (service.id === rule.all_inclusive_service_id) {
            // remove all services of this retail type service id
            servicesFiltered = props.cart.filter(
              (item) => serviceTypeId
                !== (item.parent_id || item.retail_service_type_id),
            );
            // debugger;
          }

          // if anything except inclusive service is being added
          if (service.id !== rule.all_inclusive_service_id) {
            // remove all inclusive service if added
            servicesFiltered = props.cart.filter(
              (item) => rule.all_inclusive_service_id !== (item.id || item.service_id),
            );
            // debugger;
          }
          // debugger;
        }
        // debugger;
      }
    }
  }
  if (servicesFiltered) return [...servicesFiltered, service];
  return [...props.cart, service];
};
const createCartObj = (service) => {
  const temp = {};
  const allKeys = Object.keys(service);
  let parentId = null;
  let childId = null;
  const type = 'Service';
  if (service.retail_service_type_id || service.retail_service_type_id === 0) {
    parentId = service.retail_service_type_id;
  }
  if (service.parent_id || service.parent_id === 0) parentId = service.parent_id;
  if (service.add_on_category_id || service.add_on_category_id === 0) parentId = service.add_on_category_id; // for add on services
  temp.parent_id = parentId;
  if (service.sub_category_id) childId = service.sub_category_id;
  temp.childId = childId;
  temp.service_id = service.id || service.service_id;
  temp.title = service.name || service.title;
  temp.total_amount = priceCalculator(service).applicablePrice
    ? priceCalculator(service).applicablePrice
    : 0;
  temp.desc = '';
  temp.type = service.type || type;
  if (service.count) temp.count = service.count;
  if (service.individualPrice) temp.individualPrice = service.individualPrice;

  if (service.oil_brand) temp.oil_brand = service.oil_brand;


  temp.package_details = service.package_details
    ? service.package_details
      .filter((service) => !!service)
      .map((service) => {
        let id = null;
        if (typeof service === 'number') {
          id = service;
        }
        if (service.id) {
          id = service.id;
        }
        return id;
      })
    : null;

  return temp;
};
export const makeCartAPIObject = (services) => {
  const allowedKeys = [
    // 'retail_service_type_id',
    // 'id',
    // 'strike_through_price',
    // 'price_details',
    // 'material_cost',
    // 'labour_cost',
    // 'tax',
    // 'service_type_id',
    // 'name',
  ];
  const props = storeExport.store.getState();

  const servicesData = services.map((service) => createCartObj(service));


  const purchase_items = servicesData.filter(
    (service) => ((service.parent_id === -4) || (service.parent_id === -3) || (service.parent_id === 21 && service.childId === 4)),
  ).map(
    (item) => {
      const count = item.count || 1;
      const merge = {};
      if (item.individualPrice) {
        merge.individualPrice = item.individualPrice;
      } else if (count === 1) {
        merge.individualPrice = item.total_amount;
      }

      return ({
        count,
        name: item.title,
        id: item.service_id,
        service_id: item.service_id,
        total_amount: item.total_amount,
        type: item.parent_id === -4 ? 'battery' : 'tyre',
        parent_id: item.parent_id,
        sub_category_id: item.childId,
        ...merge,
      });
    },
  );

  const couponCodeProvider = () => {
    if (servicesData.length === 0) return '';
    return props.coupon && props.coupon.coupon_code
      ? props.coupon.coupon_code
      : '';
  };
  return {
    services: servicesData.filter(
      (service) => ((service.parent_id !== -4) && (service.parent_id !== -3) && (service.childId !== 4)),
    ),
    car_id: props.cardetails?.fuel.car_type_id,
    city_id: props.city?.id,
    coupon_code: couponCodeProvider(),
    gom_discount: 0,
    subscription_used: false,
    use_gom_credits: false,
    total_amount: getCartTotal(services),
    user_car_id: props.cardetails?.fuel.car_type_id,
    purchase_items,
  };
};

export const isPurchaseItem = (item) => item.sub_category_id === 4 || item.parent_id === -4;

export const removeUnNecessaryKeys = (cartIems) => cartIems.map(
  (cartItem) => {
    if(!cartItem?.size){
      cartItem.size = cartItem?.tyre_size;
    }
    const finalObj = {};
    const keysInCartItem = Object.keys(cartItem);
    for (let i = 0; i < keysInCartItem.length; i++) {
      // remove empty issues
      if (keysInCartItem[i] === 'issues') {
        if (cartItem[keysInCartItem[i]].length === 0) {
          continue;
        }
      }
      if (cartApiKeysToSend.includes(keysInCartItem[i])) {
        finalObj[keysInCartItem[i]] = cartItem[keysInCartItem[i]];
      }
    }
    return finalObj;
  },
);

export const makeCartApiObjectV3 = (cartItems, couponCode) => {
  const temp = {};
  if (couponCode !== undefined) {
    temp.coupon_code = couponCode;
  }
  const purchase_items = cartItems.filter(
    (item) => isPurchaseItem(item),
  );
  const services = cartItems.filter(
    (item) => !isPurchaseItem(item),
  );

  const appState = storeExport.store.getState();
  return ({
    purchase_items: removeUnNecessaryKeys(purchase_items),
    services: removeUnNecessaryKeys(services),
    car_id: appState.cardetails?.fuel?.car_type_id,
    city_id: appState.city?.id,
    use_gom_credits: false,
    coupon_code: temp.coupon_code !== undefined ? temp.coupon_code : appState.cart?.coupon,
    ...temp,
  });
};

export const serviceDetailsCarouselImage = (service) => {
  if ((service.carousel_images && service.carousel_images.length) || service.video_url) {
    let videoArr = [];
    let ImageArr = [];
    if(service.video_url) {
      service.video_url.forEach(videoUrl => {
        videoArr.push({
          url:videoUrl.replace('dashdensity','hdpi'),
          type:'video',
        });
      })
    }
    if(service.carousel_images){
      service.carousel_images.forEach(image => {
        ImageArr.push({
          url:image,
          // type:'image',
        });
      })

    } else if(service.image_url) {
        ImageArr.push({
          url: service.image_url,
        })
    }
    // if (service.services_assets) {
    //   arr.push(service.services_assets);
    // } else {
    //   arr.push(service.image_url);
    // }
    // const arr = [];
    // if (service.services_assets) {
    //   arr.push(service.services_assets);
    // } else {
    //   arr.push(service.image_url);
    // }
    // remove duplicate images from array
    // console.log("video url comes", service);
    // console.log("vi url comes", service.video_url);
    // console.log("image url comes", ImageArr);

    return [...new Set([...videoArr,...ImageArr])];
  }
  if (service.package_details) {
    return service.package_details.map(
      (packageDetail) => packageDetail.services_assets || packageDetail.image_url,
    );
  }
  return [service.services_assets || service.image_url];
};

export function timeConverter(UNIX_timestamp) {
  const a = new Date(UNIX_timestamp);
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const year = a.getFullYear();
  const month = months[a.getMonth()];
  const date = a.getDate();
  const hour = a.getHours();
  const min = a.getMinutes();
  const sec = a.getSeconds();
  return {
    year,
    month,
    date,
    hour,
    min,
    sec,
  };
}

export const randomGenerator = (length) => {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
  return result;
};

export const fuelIconProvider = (fuel) => {
  switch (fuel.name.toLowerCase()) {
    case 'electric': 
      return 'https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/fuel_type/electric.svg';
    case 'cng':
      return 'https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/fuel_type/CNG.svg';
    case 'diesel':
      return 'https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/fuel_type/DIESEL.svg';
    case 'petrol':
      return 'https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/fuel_type/PETROL.svg';
    default:
      return 'https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/fuel_type/CNG.svg';
  }
};

export const setCartDependenciesInStore = (cartResponse) => {
  const appState = storeExport.store.getState();
  const { car } = cartResponse;
  if (car) {
    if (car.model && car.brand && car.car_type) {
      storeExport.store.dispatch(
        setCarForCart({
          brand: car.brand,
          model: { ...car.model, slug: slugify(car.model.name) },
          fuel: { ...car.car_type, car_type_id: car.id },
        }),
      );
    }
    // const carInStore = appState.cardetails;
    // if (!carInStore.brand || !carInStore.model || !carInStore.fuel) {
    //   storeExport.store.dispatch(
    //     setUserCar({
    //       brand: car.brand,
    //       model: { ...car.model, slug: slugify(car.model.name) },
    //       fuel: { ...car.car_type, car_type_id: car.id },
    //     }),
    //   );
    // }
  }
  const { coupon } = cartResponse;
  if (coupon) {
    storeExport.store.dispatch(setCoupon(cartResponse.coupon));
  }
  storeExport.store.dispatch(setAddOnServices([]));
  storeExport.store.dispatch(setCartResolved(true));
};

export const isUserLoggedIn = () => {
  const appState = storeExport.store.getState();

  return !!(appState.user && appState.user.is_logged_in);
};

const radians = (degree) => {
  // degrees to radians
  const rad = (degree * Math.PI) / 180;

  return rad;
};

const getDistanceInKm = (lat1, lon1, lat2, lon2) => {
  let dlat;
  let dlon;
  let a;
  let c;
  let R;

  R = 6371; // km
  dlat = radians(lat2 - lat1);
  dlon = radians(lon2 - lon1);
  lat1 = radians(lat1);
  lat2 = radians(lat2);
  a = Math.sin(dlat / 2) * Math.sin(dlat / 2)
    + Math.sin(dlon / 2) * Math.sin(dlon / 2) * Math.cos(lat1) * Math.cos(lat2);
  c = 2 * Math.asin(Math.sqrt(a));
  const d = R * c;
  return d;
};

export const getNearByStores = (city, storeLatLong, city_wise_stores) => {
  const nearByStores = [];

  const lat = storeLatLong && storeLatLong.latitude
    ? storeLatLong.latitude
    : city.latitude;
  const long = storeLatLong && storeLatLong.longitude
    ? storeLatLong.longitude
    : city.longitude;

  const keys = Object.keys(city_wise_stores);
  for (let i = 0; i < keys.length; i++) {
    if (
      city_wise_stores[keys[i]]
      && city_wise_stores[keys[i]].latlng
      && lat != city_wise_stores[keys[i]].latlng.latitude
    ) {
      const dummy = getDistanceInKm(
        lat,
        long,
        city_wise_stores[keys[i]].latlng.latitude,
        city_wise_stores[keys[i]].latlng.longitude,
      );

      if (dummy < 20 && nearByStores.length <= 3) nearByStores.push(city_wise_stores[keys[i]]);
    }
  }
  return nearByStores;
};

export const getCapitalizeString = (str) => {
  if (str) {
    const splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }
  return str;
};

export const getCurrentPathAndSearch = (withSearchString = true) => {
  if (typeof window !== 'undefined' && typeof window.location !== 'undefined') {
    return ({
      path: (window.location.pathname),
      ...(withSearchString ? { search: window.location.search } : {}),
    });
  }
  return ({
    path: null,
    ...(withSearchString ? { search: null } : {}),
  });
};

const getDistance = (lat1, lon1, lat2, lon2, unit) => {
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;
  let dist = Math.sin(radlat1) * Math.sin(radlat2)
    + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  if (unit === 'K') {
    dist *= 1.609344;
  }
  if (unit === 'N') {
    dist *= 0.8684;
  }
  return dist;
};

export const getNearestCityService = (cities, location) => {
  const lowest_distance = {
    distance: 9999999999,
    city: null,
  };

  for (let i = 0; i < cities.length; i++) {
    const current_city = cities[i];
    const distance = getDistance(
      current_city.latitude,
      current_city.longitude,
      location.latitude,
      location.longitude,
      'K',
    );
    if (distance < lowest_distance.distance) {
      lowest_distance.distance = distance;
      lowest_distance.city = cities[i];
    }
  }
  return lowest_distance;
};

export const getCountryName = (location) => {
  let result = '';

  if(location) {
    result = location.country_long;
    // console.log("country_long",location.country_long);
  }
  return result;
}

export const getLatitudeCity = (location) => {
  let result = '';

  if(location) {
    result = location.latitude;
    // console.log("country_long",location.latitude);
  }
  return result;
}

export const getLongitudeCity = (location) => {
  let result = '';

  if(location) {
    result = location.longitude;
    // console.log("country_llongitude",location.longitude);
  }
  return result;
}

// lucknow, chandigarh, jaipur, kanpur, nagpur and surat
// array order might be different from the above commented list
export const cityIdsForMandatoryPhoneNum = [16, 13, 22, 21, 20, 18];

