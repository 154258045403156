import { SET_SERVICE_GUIDE } from '../constants';

export default function serviceGuide(state = null, action) {
  switch (action.type) {
    case SET_SERVICE_GUIDE:
      return action.payload.value;
    default:
      return state;
  }
}
