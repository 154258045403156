import { SET_ACCESSORIES_COLLECTION_PAGE } from '../constants';

const accessoriesCollectionPage = (state = {}, action) => {
  switch (action.type) {
    case SET_ACCESSORIES_COLLECTION_PAGE:
      return action.payload.value;
    default:
      return state;
  }
};

export default accessoriesCollectionPage;
