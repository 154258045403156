/**
 * Author: Patanjali Kumar <patanjalikumar@gomechanic.in>
 * Supervision : Mr. Chandra Chowdhury <chandrachowdhury@gomechanic.in>
 *
 * Please try to read code thoroughly before contacting for any query.
 *
 * React
 * Zeplin URL : NA
 *
 * This file contains code for Main wrapper to handle onLoad of react app and call concerned onLoad APIS
 *
 * Copyright ©  2019-present GoMechanic, LLC. All rights reserved.
 *
 */

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/browser';
import useStyles from 'isomorphic-style-loader/useStyles';
import TokenHeader from '../_services/TokenHeader';
import AuthService from '../_services/auth';
import { changeAccessToken, saveUserDetails } from '../actions/user';
import { localStorageKeys } from '../constants';
import StorageBrowser from '../_services/storageBrowser';
import getDeviceTypeByWidth from '../_services/device';
import { initiateListener } from '../_services/scrollListener';
import { setDeviceType } from '../actions/device';
import { getCart, getCartV4 } from '../_services/api/cartApis';
import { getAccesoriesCart } from '../_services/api/accessories/cartApi';
import { setCart } from '../actions/setCart';
import { setUserAccessoriesCart } from '../actions/userAccessoriesCart';
import { saveLoginDetails } from '../logInUtilities/logInUtilities';
import { fetchUserDetails } from '../_services/api/fetchUserDetails';
import { getNearestCityService, setCartDependenciesInStore } from '../utilities';
import { setCartResolved } from '../actions/setCartResolved';
import { getReviewsCityWise } from '../_services/api/getReviewsCityWise';
import { reviewsCurrentCity } from '../actions/reviewsCurrentCity';
import s from '../components/variables.css';
import setUserCar from '../actions/cardetails';
import setAccessoriesCar from '../actions/accessoriesCarDetail';
import { setAccessoriesCartResolved } from '../actions/setAccessoriesCartResolved';
import { setUserSelectedCarId } from '../actions/setUserSelectedCarId';
import Toast from '../components/Toast/Toast';
import setToastConfig from '../actions/setToastConfig';
import { setCoupon } from '../actions/setCoupon';
import { setPrefferedCity } from '../actions/city';
import { cartActions, cartManipulateV3 } from '../_services/api/serviceHandlers/megaServiceCart';
import { getProductID } from '../_services/api/accessories/productApi';
import { setWarrantyCard } from '../actions/setWarrantyCard';
import { saveUserCar } from '../_services/api/saveUserCar';
import { isAmcUser } from '../actions/isAmcUser';
import { fetchCarList } from '../_services/api/fetchCarList';
import { setActiveCarsList } from '../actions/activeCars';
import { getModelsByBrand } from '../_services/api/carDetailsApi';
import { isPhonePeBrowser } from '../utilities';
import autoUtmHistory from '../_services/autoUtmHistory';
import generate from '../serverRoutes/urlGenerators/newUrl';
import history from '../history';
import { getAllAccessoriesBlogs } from '../_services/api/accessories/classificationApi';
import { setAccessoriesBlogsData } from '../actions/accBlogs';
import { getCityCarousels } from '../_services/api/getCityCarousel';
import { setCityCarousels } from '../actions/setCityCarousels';
import {fetchAllBrands} from '../_services/api/fetchAllBrands';
import {setAllBrands} from '../actions/setAllBrands';

const MainParentWrapper = (props) => {
  useStyles(s);
  const { device, setDeviceAction } = props;

  // Following is to get existing cart.

  // console.log('here are props ', props);

  useEffect(() => {
    if (!(window.location.href.includes('/accessories'))) {
      getReviewsCityWise(props.city?.id,
        props.serviceType ? props.serviceType.slug : '')
        .subscribe((res) => {
          if (res.success) props.dispatch(reviewsCurrentCity(res.reviews));
        }, console.error);
    }
  }, [props.city, props.serviceType]);

  useEffect(() => {
    getCityCarousels(props.city?.sitemap_name)
    .subscribe(res => {
      if(res.data){
        // console.log(res.data)
        props.action(setCityCarousels(res.data));
      }
    }, err => {
      console.log(err);
    })
  }, [props.city])

  const getCartFn = () => {
    getCartV4().subscribe((res) => {
      if (res.data) {
        if (res.data.car_id) {
          const cart = res.data;
          props.action(setCart(cart));
          if (cart.coupon && cart.coupon.coupon_code) {
            props.action(setCoupon(cart.coupon));
          }
          setCartDependenciesInStore(res.data);
        }
      } else {
        props.action(setCartResolved(true));
      }
    }, (err) => {
      Sentry.captureException(err);
      props.action(setCartResolved(true));
    });
  };

  // get Accessories Cart

  const getAccessoriesCartFn = () => {
    getAccesoriesCart().subscribe((res) => {
      if (res.data) {
        if (res.data) {
          props.action(setUserAccessoriesCart(res.data));
          props.action(setAccessoriesCartResolved(true));
          // console.log('car had been set ', cart.services);
        }
      } else {
        console.log('error in getting cart data');
      }
    }, (err) => {
      if (err.status === 403) {
        // props.action(setUserAccessoriesCart([]));
        props.action(setAccessoriesCartResolved(true));
      }
      Sentry.captureException(err);
    });
  };

  // get Accessories Blogs list 

  const getAccessoriesBlogList = () => {
    getAllAccessoriesBlogs().subscribe((res) => {
      if (res.data && res.status) {
        props.action(setAccessoriesBlogsData(res.data));
      } else {
        console.log('error in getting blogs data');
      }
    }, (err) => {
      console.error(err);
    });
  };

  const getUserFn = () => {
    fetchUserDetails().subscribe((res) => {
      if (res.data) {
        let data = null;
        if (res.data.data) data = res.data.data;
        else data = res.data;
        let userDetailsData = { ...data };
        if (!Object.keys(data).includes('is_logged_in')) userDetailsData = { ...userDetailsData, is_logged_in: true };

        saveLoginDetails({ ...userDetailsData });
        if (!data.id) {
          // if (isPhonePeBrowser()) {
          //   phonepeAsyncFun().then(
          //     (res) => {
          //       // setLoading(false);
          //     },
          //   ).catch(
          //     (err) => {
          //       history.replace(`/login?return=${window.location.pathname}`);
          //     },
          //   );
          // } else {
          // history.replace(`/login?return=${window.location.pathname}`);
          // }
        }
      }
    }, (err) => {
      const storageBrowser = new StorageBrowser();
      storageBrowser.clearStorageLS();
      console.error(err);
      // if (isPhonePeBrowser()) {
      //   phonepeAsyncFun().then(
      //     (res) => {
      //     // setLoading(false);
      //     },
      //   ).catch(
      //     (err) => {
      //       history.replace(`/login?return=${window.location.pathname}`);
      //     },
      //   );
      //   return;
      // }
      // alert('You have been logged out. For security reasons, page will refresh and you will be redirected to homepage.');
      if (window.location.href.includes('/accessories')) window.location.href = '/accessories';
      window.location.href = '/';
    });
  };

  const getWarrantyCard = () => {
    getProductID().subscribe((result) => {
      if (result.data) {
        props.action(setWarrantyCard(result.data));
      } else {
        console.log('error');
      }
    });
  };


  useEffect(() => {
    // console.log('runnig this');
    /* POSSIBLE BUG: action is getting called everytime as if is returning true,
    However rerender happens only when device type is changed actually */
    const handleResize = () => {
      const calculatedDeviceType = getDeviceTypeByWidth();
      if (device !== calculatedDeviceType) {
        setDeviceAction(calculatedDeviceType);
      }
    };
    handleResize();
    window.removeEventListener('resize', handleResize);
    window.addEventListener('resize', handleResize);
    handleResize();
    initiateListener();
  }, []);

  useEffect(() => {
    const header = new TokenHeader();
    const token = header.getToken();
    if (!token) {
      // if (isPhonePeBrowser()) {
      //   phonepeAsyncFun().then(
      //     (res) => {
      //     // setLoading(false);
      //     },
      //   ).catch(
      //     (err) => {
      //       if (window.location.pathname === '/login' || window.location.pathname === '/login/') return;
      //       debugger;
      //       history.replace(`/login?return=${window.location.pathname}`);
      //     },
      //   );
      //   return;
      // }
      const authService = new AuthService();
      authService.fetchToken().subscribe(
        (response) => {
          let access_token = null;
          try {
            access_token = response.data.access_token.token.trim();
          } catch (e) {
            console.error('Cannot get access token from respose');
          }
          if (access_token) {
            props.action(changeAccessToken(access_token));
            const storageBrowser = new StorageBrowser();
            storageBrowser.setStorageLs(
              localStorageKeys.AUTH_TOKEN,
              access_token,
            );
            if (!(window.location.href.includes('/accessories'))) {
              getCartFn();
            }
            getUserFn();
            if (window.location.href.includes('/accessories')) {
              getAccessoriesCartFn();
              getAccessoriesBlogList();
            }
            if (window.location.href.includes('/warranty-card')) {
              getWarrantyCard();
            }
          }
        },
        (error) => {
          console.error(`Error while fetching token : ${error}`);
        },
      );
    }
    if (token) {
      if (!(window.location.href.includes('/accessories'))) {
        getCartFn();
      }
      getUserFn();
      if (window.location.href.includes('/accessories')) {
        getAccessoriesCartFn();
        getAccessoriesBlogList();
      }
      if (window.location.href.includes('/warranty-card')) {
        getWarrantyCard();
      }
    }
  }, []);


  // Phone pe useEffect -- start
  useEffect(
    () => {
      try {
        if (props.cardetails === null || props.cardetails?.brand === null) {
          const storageBrowser = new StorageBrowser();
          const cardetailsFromLS = storageBrowser.getStorageLS('cardetails');
          if (cardetailsFromLS) {
            props.dispatch(setUserCar(cardetailsFromLS));
          }
        }
        if (!props.accessoriesCarDetail || !props.accessoriesCarDetail.brand) {
          const storageBrowser = new StorageBrowser();
          const cardetailsFromLS = storageBrowser.getStorageLS('accessoriesCarDetail');
          if (cardetailsFromLS) {
            props.dispatch(setAccessoriesCar(cardetailsFromLS));
          }
        }
      } catch (e) {
        console.error(e);
      }
    }, [],
  );

  useEffect(() => {
    if (props.user.is_logged_in) {
      if (!(window.location.href.includes('/accessories'))) {
        getCartFn();
        fetchCarList().subscribe(res => {
          props.dispatch(setActiveCarsList(res?.data));
        }, err => console.log(err));
        !props.allBrands?.length && fetchAllBrands().subscribe(res => {
          props.action(setAllBrands(res?.data))
        }, (error) => {
          console.log(error);
        })
      }
      if (window.location.href.includes('/accessories')) {
        getAccessoriesCartFn();
      }
    }
  }, [props.user.is_logged_in]);

  const setCarDetails = (carDetails) => {
    // if (carDetails.brand && carDetails.model && carDetails.fuel) props.action(setUserCar(carDetails));

    if (props.accessoriesCar) {
      props.action(setAccessoriesCar(carDetails));
    } else props.action(setUserCar(carDetails));

    // if (isPhonePeBrowser()) return;
    // if (props.redirect) {
      if(window)
      window.location = generate({
        city: props.city,
        subCity: props.selectedRegion || null,
        serviceType: props.serviceType,
        brand: carDetails.brand,
        model: carDetails.model,
        fuel: carDetails.fuel,

      });
    // }
    // }
  };

  useEffect(() => {
    if(props.user.is_logged_in && props.activeCarsList?.length > 0 && props.allBrands.length > 0 && !props.cardetails?.brand?.name && !props.cardetails?.model?.name && !props.cardetails?.fuel?.name){
      let brandId = props.activeCarsList?.[0]?.car_tbl?.brand?.id;
      let modelName = props.activeCarsList?.[0]?.car_tbl?.model?.name;
      let fuelName = props.activeCarsList?.[0]?.car_tbl?.type?.toLowerCase();
      let brand = props.allBrands?.find(brand => brand?.id === brandId);
      const carDetails = props.accessoriesCar ? props.accessoriesCarDetail : props.cardetails;
      if(brand && brandId){
        getModelsByBrand(brandId).subscribe((res) => {
          res.data = res?.data?.map((model) => ({
            ...model,
            slug: model.name.toLowerCase().replace(/\s+/g, '-'),
          }));
          // console.log(res.data)
          let model = res.data?.find(model => model?.name === modelName);
          let fuel = model?.fuel?.find(fuel => fuel?.name?.toLowerCase() === fuelName);
          setCarDetails({...carDetails, brand, model, fuel })
        }, (error) => {
          setLoadingModels(false);
          console.error(error);
        });
      }
    }
  },[props.user.is_logged_in, props.allBrands, props.activeCarsList])

  useEffect(() => {
    if (props.cardetails?.fuel && props.cardetails?.fuel.car_type_id) {
      props.dispatch(setUserSelectedCarId(null));
      props.dispatch(isAmcUser(null));
    }
  }, [props.cardetails]);
  // Phone pe useEffect -- end

  // console.log('store here ', props);


  // useEffect to change city on address selection

  useEffect(() => {
    if (props.user && props.user.address && props.user.address.length) {
      let addressObj = null;

      for (let i = 0; i < props.user.address.length; i++) {
        if (props.selectedAddressId === props.user.address[i].id) {
          addressObj = props.user.address[i];
          break;
        }
      }
      // console.log({ addressObj });
      if (addressObj && addressObj.latitude && addressObj.longitude) {
        const nearest = getNearestCityService(props.allCities, addressObj);
        if (nearest && nearest.city) {
          props.dispatch(setPrefferedCity(nearest.city));
        }
      }
    }
  }, [props.selectedAddressId]);

  // Start Set city and city id in Localstorage
  useEffect(() => {
    if(props.city) {
      const storageBrowser = new StorageBrowser();
      storageBrowser.setStorageLs(
        localStorageKeys.city,
        props.city?.sitemap_name,
      );
      storageBrowser.setStorageLs(
        localStorageKeys.city_id,
        props.city?.id,
      );
    }
  },[props.city]);
  // End Set city and city id in Localstorage

  useEffect(() => {
    if (props.city
      && props.city?.id) {
      if (props.cart.all_services && props.cart.all_services.length) {
        // console.log('here hit this');
        cartManipulateV3({
          type: cartActions.updateCity,
        });
      }
    }
  }, [props.city]);

  useEffect(() => {
    if (!props.user.is_logged_in
      && props.cardetails
      && props.cardetails?.brand
      && props.cardetails?.model
      && props.cardetails?.fuel
      && props.cardetails?.fuel.car_type_id) {
      if (props.cart.all_services && props.cart.all_services.length) {
        cartManipulateV3({
          type: cartActions.updateCar,
        });
      }
    }
  }, [props.cardetails]);


  useEffect(() => {
    const { fuel } = props.cardetails;
    const { cardetails } = props;
    if (!props.user.is_logged_in) return;
    if (props.user.cars && fuel && fuel.car_type_id) {
      const carIdFromUserCars = props.user.cars.reduce(
        (acc, car) => (car.car_type_id === fuel.car_type_id ? car.id : acc), null,
      );
      const amcFromUserCars =  props.user.cars.reduce(
        (acc,car) => ((car.car_type_id === fuel.car_type_id) && car.is_amc ? car.is_amc : acc), null,
      );
      if (carIdFromUserCars) {
        props.dispatch(setUserSelectedCarId(carIdFromUserCars));
        props.dispatch(isAmcUser(amcFromUserCars));
        // console.log('Car was set from user cars');
        setTimeout(() => {
          if (props.cart.all_services && props.cart.all_services.length) {
            cartManipulateV3({
              type: cartActions.updateCar,
            });
          }
        }, 20);
        return;
      }
    }

    if (fuel && fuel.car_type_id) {
      saveUserCar({
        city_id: props.city?.id,
        car_type_id: fuel.car_type_id,
        registration_no: `${cardetails.brand.name[0]}${cardetails.model.name.toLowerCase()}${cardetails.fuel.name.toLowerCase()}${props.user.mobile}`,
      }).subscribe(
        (res) => {
         if(res.data) props.dispatch(setUserSelectedCarId(res.data?.id));
          const { user } = props;
          const cars = user.cars || [];
          cars.push(res.data);
          props.dispatch(saveUserDetails({ ...user, cars }));
          setTimeout(() => {
            if (props.cart.all_services && props.cart.all_services.length) {
              cartManipulateV3({
                type: cartActions.updateCar,
              });
            }
          }, 20);
        }, (err) => {
          // alert(JSON.stringify(err));
          console.error(err);
          setToastConfig({ show: true, text: 'Car cannot be saved.' });
        },
      );
    }
  }, [props.user.is_logged_in, props.cardetails]);

  return (
    <>
      {props.children}
      {props.toast.show ? (
        <Toast
          hideHandler={() => {
            props.dispatch(setToastConfig({
              show: false,
              text: '',
            }));
          }}
          text={props.toast.text}
        />
      ) : null}
      {' '}

    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
    dispatch: (action) => dispatch(action),
    setDeviceAction: (value) => dispatch(setDeviceType(value)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MainParentWrapper);
