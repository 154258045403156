import { SET_MONSOON_DATA } from '../constants';

export default function setMonsoonCatId(state = null, action) {
  switch (action.type) {
    case SET_MONSOON_DATA:
      return action.payload.value;
    default:
      return state;
  }
}
