import { SET_CAR_BRAND_PAGE } from '../constants';

const carBrandPageData = (state = {}, action) => {
  switch (action.type) {
    case SET_CAR_BRAND_PAGE:
      return action.payload.value;
    default:
      return state;
  }
};


export default carBrandPageData;
