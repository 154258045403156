// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@media (min-width:1024px){html{font-size:13px!important}}@media (min-width:1279px){html{font-size:14px!important}}@media (min-width:1366px){html{font-size:15px!important}}@media (min-width:1440px){html{font-size:16px!important}}@media (min-width:1680px){html{font-size:17px!important}}@media (min-width:1920px){html{font-size:18px!important}}:root{--font-family-base:\"Gilroy\",sans-serif!important;--max-content-width:1000px;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px}._2uFmH{display:-ms-flexbox;display:flex}._2cVbM{-ms-flex-direction:row;flex-direction:row}._1feG2{-ms-flex-direction:column;flex-direction:column}._2-rG4{-ms-flex-wrap:wrap;flex-wrap:wrap}._3YAW1{-ms-flex-pack:center;justify-content:center}._10i5T{-ms-flex-align:center;align-items:center}._1IwjF{font-weight:700}html{background-color:#f6f6f6;font-family:Gilroy,sans-serif}body,html{margin:0 auto}._116vK{font-size:13px;font-weight:400;font-stretch:normal;font-style:normal;letter-spacing:.12px;text-align:left;color:#4a4a4a}@media (max-width:1023px){._3XIu5,._2SJmN{display:none!important}}@media (min-width:1024px){._2G6BP,._1FMXy{display:none!important}}@media only screen and (max-width:1023px){._1QvE5,._3XIu5{display:none!important}}", ""]);
// Exports
exports.locals = {
	"dFlex": "_2uFmH",
	"flexRow": "_2cVbM",
	"flexColumn": "_1feG2",
	"flexWrap": "_2-rG4",
	"justifyCenter": "_3YAW1",
	"alignCenter": "_10i5T",
	"fontBold": "_1IwjF",
	"font13Normal": "_116vK",
	"hideOnMobileTab": "_3XIu5",
	"hideOnTab": "_2SJmN",
	"hideOnPc": "_2G6BP",
	"hideOnPcTab": "_1FMXy",
	"hideOnMobile": "_1QvE5"
};
module.exports = exports;
