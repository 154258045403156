import {SET_REVIEW_PAGE_DATA} from '../constants';

export default function reviewPageData(state = null, action) {
    switch (action.type) {
      case SET_REVIEW_PAGE_DATA:
        return action.payload.value;
      default:
        return state;
    }
  }
  