import { SET_ACTIVE_CARS } from '../constants';

const activeCarsList = (state = [], action) => {
  switch (action.type) {
    case SET_ACTIVE_CARS:
      return action.payload.value;
    default:
      return state;
  }
};


export default activeCarsList;
