export const deviceTypes = {
  MOBILE: 'MOBILE',
  TABLET: 'TABLET',
  DESKTOP: 'DESKTOP',
};

const getDeviceTypeByWidth = () => {
  let width = null;
  if (window) width = window.innerWidth > 0 ? window.innerWidth : screen.width;
  else width = 360;

  if (width <= 1023) return deviceTypes.MOBILE;
  if (width > 1023) return deviceTypes.DESKTOP;
  // if (width > 768 && width <= 1023) return deviceTypes.TABLET;
  // if (width > 1023) return deviceTypes.DESKTOP;

  return deviceTypes.MOBILE;
};

export default getDeviceTypeByWidth;
