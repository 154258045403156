import { SET_POLICY } from "../constants";

export default function policy(state = null, action) {
    switch (action.type) {
      case SET_POLICY:
        return action.payload.value;
      default:
        return state;
    }
  }
  