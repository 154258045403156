import { SET_USER_CART } from '../constants';

export default function cart(state = null, action) {
  switch (action.type) {
    case SET_USER_CART: {
      if (typeof window !== 'undefined' && window.gtag) {
        if(action.payload.value.all_services) {
          const cartItems = action.payload.value.all_services.map((item) => ({
            name: item.name,
            item_id: item.id,
          }));
        }
        // window.gtag('event', events.add_to_cart, {
        //   cart_items: cartItems,
        // });
      }
      return action.payload.value;
    }
    default:
      return state;
  }
}
