import { SET_ACCESSORIES_VEHICLE_TYPE } from '../constants';

const accessoriesVehicleType = (state = 'Car', action) => {
  switch (action.type) {
    case SET_ACCESSORIES_VEHICLE_TYPE:
      return action.payload.value;
    default:
      return state;
  }
};

export default accessoriesVehicleType;
