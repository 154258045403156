export function toCamelCase(str) {
    var output = "";
    str.replace("-", " ").split(" ").forEach(function (word, index) {
        if (!!word && word.length >= 1) {
            var add = word[0].toUpperCase() + word.slice(1);
            output += add + " ";
        }

    });
    return output;
}

export const getSitemapMetaTitle = (pathname) => {
    const pathSplittedArray = pathname.split("/");

    if (pathname.includes("subzones")) {
        const citySlug = pathSplittedArray[3];
        const subZoneSlug = pathSplittedArray[4];
        return toCamelCase(`${citySlug} ${subZoneSlug ? subZoneSlug : ""} Sitemap - GoMechanic`)
    }
    else if (pathname === "/directory" || pathname === "/directory/") {
        return 'Sitemap - GoMechanic';
    }
    else {
        const citySlug = pathSplittedArray[2];
        return toCamelCase(`${citySlug} Sitemap - GoMechanic`);
    }
}


export const getSitemapMetaDescription = (pathname) => {
    const pathSplittedArray = pathname.split("/");

    if (pathname.includes("subzones")) {
        const citySlug = pathSplittedArray[3];
        const subZoneSlug = pathSplittedArray[4];
        return toCamelCase(`GoMechanic ${citySlug} ${!!subZoneSlug ? subZoneSlug : ""} Sitemap - Car Services & Repair for all Brands & Models listed on GoMechanic.`);
    }
    else if (pathname === "/directory" || pathname === "/directory/") {
        return 'GoMechanic Sitemap - Car Services & Repair for all Brands & Models listed on GoMechanic.';
    }
    else {
        const citySlug = pathSplittedArray[2];
        return toCamelCase(`GoMechanic ${citySlug} Sitemap - Car Services & Repair for all Brands & Models listed on GoMechanic.`);
    }
}
