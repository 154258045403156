import { SET_ACC_BLOGS_DATA } from '../constants';

const accBlogs = (state = null, action) => {
  switch (action.type) {
    case SET_ACC_BLOGS_DATA:
      return action.payload.value;
    default:
      return state;
  }
};

export default accBlogs;
