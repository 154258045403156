import { CHANGE_ACCESS_TOKEN, LOGIN_USER, UPDATE_USER_DETAILS } from '../constants';

export default function user(state = {}, action) {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        ...action.payload,
        // is_logged_in: true,
        is_updated: true,
      };
    case UPDATE_USER_DETAILS:
      return {
        ...state,
        ...action.payload,
      };
    case CHANGE_ACCESS_TOKEN:
      return {
        ...state,
        token: action.payload.value,
        is_updated: true,
      };
    default:
      return state;
  }
}
