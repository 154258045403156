import { SET_MILES_DATA } from '../constants';


export default function milesProductData(state = null, action) {
  // console.log(action)
  switch (action.type) {
    case SET_MILES_DATA: {
      return action.payload.value;
    }
    default:
      return state;
  }
}
