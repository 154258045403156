export const COOKIE_EXPIRATION = new Date(2021, 1, 1, 17, 30);

export default class Global {
  // All DeV Constant
  urls = {
    api_get: 'https://gomechanic.app/api/',
    api_post: 'https://gomechanic.app/api/',
    dev_api: 'https://gomechanic.app/api/',
    phonePeCallBack: 'https://gomechanic.app/api/v1/oauth/order/save_phonepe_payment',
    pwaUser: 'https://gomechanic.app/api/create_pwa_user/',
    accessories_url: 'https://devaccessories.gomechanic.app/',
    api_bike: "https://leads.gomechanic.app/",
    api_workshop: "https://eapp.gomechanic.app/",
    api_ML: "https://leads.gomechanic.app/"
  };

  nigerianEndPoints = {
    api_get: 'https://ng.gomechanic.app/api/',
    api_post: 'https://ng.gomechanic.app/api/',
    dev_api: 'https://ng.gomechanic.app/api/',
    phonePeCallBack: 'https://ng.gomechanic.app/api/v1/oauth/order/save_phonepe_payment',
    pwaUser: 'https://ng.gomechanic.app/api/create_pwa_user/'

  };

  devEndpoints = {
    api_get: 'https://demo.gomechanic.app/api/',
    api_post: 'https://demo.gomechanic.app/api/',
    dev_api: 'https://demo.gomechanic.app/api/',
    phonePeCallBack: 'https://demo.gomechanic.app/api/v1/oauth/order/save_phonepe_payment',
    pwaUser: 'https://demo.gomechanic.app/api/create_pwa_user/',
    accessories_url: 'https://devaccessories.gomechanic.app/',
    api_bike: "https://devleads.gomechanic.app/",
    api_workshop: "https://staging.eapp.gomechanic.app/",
    api_ML: "https://stageleads.gomechanic.app/"
  };

  constructor() {
    this.urls = { ...this.urls, ...this.devEndpoints};
    if(typeof window !== 'undefined' && window.App && window.App.apiEndpoints){
      this.urls = { ...this.urls, ...window.App.apiEndpoints};
    }
    // console.log('urls ', this.urls);
    // if(typeof window !== 'undefined'){
    //   if(window.location.href.indexOf('.ng')!==-1){
    //     this.urls = {...this.nigerianEndPoints};
    //   }
    // }
    if (!__DEV__) {
      // Modify constant for DEV
      // this.urls = {
      //   api_get: 'https://gomechanic.app/api/',
      //   api_post: 'https://gomechanic.app/api/',
      //   dev_api: 'https://gomechanic.app/api/',
      //   phonePeCallBack: 'https://gomechanic.app/api/v1/oauth/order/save_phonepe_payment',
      // };

    }
  }
}
