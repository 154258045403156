// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3jiRb{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;position:fixed;bottom:20%;font-weight:500;font-stretch:normal;font-style:normal;line-height:1.46;letter-spacing:normal;text-align:left;color:#fff;width:100%;z-index:50000}._3jiRb>div{border-radius:40px;background-color:#5a5a5a;padding:15px 25px;max-width:300px}", ""]);
// Exports
exports.locals = {
	"container": "_3jiRb"
};
module.exports = exports;
