import { SET_REVIEWS_CURRENT_CITY } from '../constants';

export default function reviewsCurrentCity(state = null, action) {
  switch (action.type) {
    case SET_REVIEWS_CURRENT_CITY:
      return action.payload.value;
    default:
      return state;
  }
}
