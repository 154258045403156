/* eslint-disable camelcase */
export const select_city = 'select_city';
export const select_brand = 'web_select_brand';
export const select_model = 'web_select_model';
export const tap_check_price = 'web_tap_check_price';
export const add_to_cart = 'web_add_to_cart';
export const begin_checkout = 'web_begin_checkout';
export const view_item = 'web_view_item';
export const view_item_list = 'web_view_item_list';
export const view_cart = 'web_view_cart';
export const tap_on_monsoon_list = 'tap_on_monsoon_list';
export const login = 'login';
export const sign_up = 'sign_up';
export const purchase = 'web_purchase';
export const lux_purchase = 'web_luxury_purchase';
export const download_app = 'download_app';
export const tap_popular_service = 'tap_popular_service';
export const tap_searches = 'web_tap_search';
// export const click_banner = 'click_banner';
export const search_bar_acc = 'search_bar_acc';
export const select_cat_scroll_acc = 'select_cat_scroll_acc';
export const cta_explore = 'cta_explore';
export const select_top_category = 'select_top_category';
export const select_sub_category = 'select_sub_category';
export const select_product_page_section = 'select_product_page_section';
// export const cta_add_to_cart_acc = 'cta_add_to_cart_acc';
export const cta_buy_now = 'cta_buy_now';
// export const cta_purchase_acc = 'cta_purchase_acc';
// export const cta_begin_checkout_acc = 'cta_begin_checkout_acc';
export const apply_coupon_acc = 'apply_coupon_acc';
export const filter_acc = 'filter_acc';
// export const view_item_acc = 'view_item_acc';
export const cta_know_more_beat = 'cta_know_more_beat';
export const cta_submit_now_beat = 'cta_submit_now_beat';
export const tap_gom_icon = 'web_tap_gom_icon';
export const data_time = 'web_data_time';
export const select_date = 'web_change_date';
export const select_time = 'web_select_time_slot';
export const web_click_change_address = 'web_click_change_address';
export const arrival_mode = 'arrival_mode';
export const remove_item = 'web_remove_item';
export const payment_mode = 'web_payment_mode';
// export const cta_continue_pay = 'cta_continue_pay';
// enhanced ecommerce events start
export const view_impression = 'eec.impressionView';
export const impression_click = 'eec.impressionClick';
export const view_detail = 'eec.detail';
export const acc_add_to_cart = 'eec.add';
export const view_promotion = 'eec.promotionView';
export const promotion_click = 'eec.promotionClick';
export const remove_from_cart = 'eec.remove';
export const checkout_progress = 'eec.checkout';
export const set_checkout_option = 'eec.checkout_option';
export const acc_purchase = 'eec.purchase';
export const select_car = 'eec.select_car';
export const select_car_brand = 'eec.select_car_brand';
export const select_car_model = 'eec.select_car_model';
export const select_fuel_type = 'eec.select_fuel_type';
export const select_year = 'eec.select_year';
export const payment_mode_acc = 'eec.payment_mode';
export const new_address = 'eec.new_address';
// enhanced ecommerce events end
export const tap_next_acc = 'tap_next_acc';
export const cta_submit_acc = 'cta_submit_acc';
export const enq_enter_number = 'enter_phone_number';
export const enq_enter_location = 'enter_location';
export const enq_request_call = 'request_call';
export const enq_tap_call = 'tap_call';
export const enq_tap_faq = 'tap_faq';
export const select_address_acc = 'select_address_acc';
export const submit_email = 'submit_email';
export const proceed_pay = 'proceed_pay';
export const init_login = 'init_login';
export const get_otp = 'get_otp';
export const resend_otp = 'resend_otp';
export const click_banner = 'click_banner';
export const view_trending_products = 'view_trending_products';
export const tap_search_by = 'tap_search_by';
export const tap_address_selection = 'tap_address_selection';
export const items_clicked = 'items_clicked';
export const tap_rails_view_all = 'tap_rails_view_all';
export const fetch_my_location = 'fetch_my_location';
export const change_address = 'change_address';
export const add_recommended = 'eec.add_recommended';
export const add_frequent = 'eec.add_frequent';
export const search_click = 'search_click';
export const logout = 'logout';
export const tap_back_detail = 'tap_back_detail';
export const enter_subscriber = 'enter_subscriber';
export const submit_subscriber = 'submit_subscriber';
export const notify_me = 'notify_me';
export const acc_register_warranty = 'acc_register_warranty';
export const tap_hamburger = 'tap_hamburger';
export const tap_my_order = 'tap_my_order';
export const init_search = 'init_search';
export const search_popular_searches = 'search_popular_searches';
export const search_top_products = 'search_top_products';
export const select_top_banner = 'select_top_banner';
export const select_popular_categories = 'select_popular_categories';
export const select_popular_brands = 'select_popular_brands';
export const select_about_us = 'select_about_us';
export const view_review = 'view_review';
export const view_homepage = 'web_view_homepage';
export const open_category_dropdown = 'open_category_dropdown';
export const select_partner_brand = 'select_partner_brand';
export const page_count = 'page_count';
export const sort_products = 'sort_products';
export const share_button = 'share_button';
export const acc_enter_pincode = 'acc_enter_pincode';
export const acc_pincode_submit = 'acc_pincode_submit';
export const view_specifications = 'view_specifications';
export const select_popular_searches = 'select_popular_searches';
export const view_all_offers = 'view_all_offers';
export const see_all_reviews = 'see_all_reviews';
export const select_top_subcategory_collection = 'select_top_subcategory_collection';
export const select_lower_category_collection = 'select_lower_category_collection';
export const select_lower_marketing_collection = 'select_lower_marketing_collection';
export const select_filter_collection = 'select_filter_collection';
export const select_video_collection = 'select_video_collection';
export const view_collection_page = 'view_collection_page';
export const click_source_rail = 'click_source_rail';
export const click_adj_cat_rail = 'click_adj_cat_rail';
export const view_google_collection = 'view_google_collection';
export const acc_prod_read_more = 'read_more';
export const click_view_similar = 'click_view_similar';
export const click_warranty_details = 'click_warranty_details';
export const click_review_rating = 'click_review_rating';
export const select_product_size = 'select_product_size';
export const open_faq_section = 'open_faq_section';
export const open_review_rating_section = 'open_review_rating_section';
export const view_product_video = 'view_product_video';
export const acc_faq = 'acc_faq';
export const view_sub_category = 'view_subcategory';
export const view_product_image = 'view_product_image';
export const acc_add_more = 'acc_add_more';
export const acc_remove_quantity = 'acc_remove_quantity';
export const view_bill = 'view_bill';
export const click_magnifier_icon = 'click_magnifier_icon';
export const view_brand_page = 'view_brand_page';
export const select_sub_category_brand_page = 'select_sub_category_brand_page';
export const select_quick_filter_brand_page = 'select_quick_filter_brand_page';
export const select_export_category = 'select_export_category';
export const contact_us_detail = 'contact_us_detail';
export const submit_details = 'submit_details';
export const view_gallery = 'view_gallery';
export const click_gom_export_details = 'click_gom_export_details';
export const click_export_map = 'click_export_map';
export const export_read_more = 'export_read_more';

// retail new events
export const tap_miles_membership = 'tap_miles_membership';
export const tap_activated_miles = 'tap_activated_miles';
export const tap_animation = 'web_tap_animation';
export const init_coupon = 'web_init_coupon';
export const apply_coupon = 'web_apply_coupon';
export const tap_booster = 'web_tap_booster';
export const tap_side_menu = 'tap_side_menu';
export const view_side_menu = 'view_side_menu';
export const tap_expert_videos = 'tap_expert_videos';
export const view_search_results = 'web_view_search_results';
export const search_clicked = 'web_search_clicked';
export const int_chat = 'int_chat';
export const end_chat = 'end_chat';
export const mid_banner_click = 'mid_banner_click';
export const tap_gom_assurance = 'tap_gom_assurance';
export const add_description = 'add_description';
export const web_tap_miles_banner = 'web_tap_miles_banner';
export const tap_curated_custom_repairs = 'tap_curated_custom_repairs';
export const tap_back = 'tap_back';
export const tap_coverage_details = 'tap_coverage_details';
export const tap_coverage_details_sub_tabs = 'tap_coverage_details_sub_tabs';
export const tap_close = 'tap_close';
export const tap_coverage_name = 'tap_coverage_name';
export const tap_see_more = 'tap_see_more';
export const tap_warranty_faqs = 'tap_warranty_faqs';
export const web_tap_warranty_banner = 'web_tap_warranty_banner';
export const tap_service_sub_category = 'tap_service_sub_category';
export const tap_see_less = 'tap_see_less';
export const tap_claim = 'tap_claim';
export const tap_book_claim = 'tap_book_claim';
export const click_book = 'click_book';
export const web_init_login = 'web_init_login';
export const web_get_otp = 'web_get_otp';
export const web_login = 'web_login';
export const web_tap_select_car = 'web_tap_select_car';
export const web_select_fuel_type = 'web_select_fuel_type';
export const web_click_banner = 'web_click_banner';
export const web_luxury_atc = 'web_luxury_atc';
export const web_click_call_button = 'web_click_call_button';
export const web_click_city = 'web_click_city';
export const web_click_detect_location = 'web_click_detect_location';
export const web_change_city = 'web_change_city';
export const web_tap_apply_coupon = 'web_tap_apply_coupon';
export const web_apply_goapp = 'web_apply_goapp';
export const web_select_address = 'web_select_address';
export const web_open_app_store = 'web_open_app_store';
export const web_get_app_link = 'web_get_app_link';
export const web_view_miles = 'web_view_miles';
export const web_view_warranty = 'web_view_warranty';
export const web_miles_to_cart = 'web_miles_to_cart';
export const web_init_atc = 'web_init_atc';
export const web_click_cat = 'web_click_cat';
export const web_click_item = 'web_click_item';
export const web_click_faq = 'web_click_faq';
export const web_remove_coupon = 'web_remove_coupon';
export const web_click_coupon = 'web_click_coupon';
