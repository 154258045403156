import HttpHeader from '../httpHeader';
import Global from '../../global';
import { Http } from '../../modules/http';
import storeExport from '../../clientStore';

export const addToCartApi = (cartData) => {
  const appState = storeExport.store.getState();
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  let endpoint = '';
  // Endpoint is different for logged in and logged out users.
  if (appState.user && appState.user.is_logged_in) endpoint = `${ApiManager.urls.api_post}v1/oauth/customer/cart-pricing1`.trim();
  else endpoint = `${ApiManager.urls.api_post}v1/oauth/customer/cart-pricing-temp`.trim();

  const headers = Headers.get_token_headers();
  const url = endpoint;
  return HttpHandler.post(
    url,
    {
      ...cartData,
    },
    headers,
  );
};

export const getCart = () => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const endpoint = `${ApiManager.urls.api_get}v1/oauth/customer/get-cart-temp`;

  const headers = Headers.get_token_headers();
  return HttpHandler.get(endpoint, headers);
};

export const getCartV3 = () => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const endpoint = `${ApiManager.urls.api_get}v3/oauth/customer/get_user_cart`;

  const headers = Headers.get_token_headers();
  return HttpHandler.get(endpoint, headers);
};

export const getCartV4 = () => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const endpoint = `${ApiManager.urls.api_get}v2/oauth/customer/get_user_cart_v4`;

  const headers = Headers.get_token_headers();
  return HttpHandler.get(endpoint, headers);
};
