import { SET_TOAST_CONFIG } from '../constants';

export default function toast(state = {
  show: false,
  text: '',
}, action) {
  switch (action.type) {
    case SET_TOAST_CONFIG: {
      return action.payload.value;
    }
    default:
      return state;
  }
}
