import { SET_OFFERS_PAGE } from "../constants";

export default function offersPage(state = null, action) {
    switch (action.type) {
      case SET_OFFERS_PAGE:
        return action.payload.value;
      default:
        return state;
    }
  }
  