import { SET_ADD_ON_SERVICES } from '../constants';

export function setAddOnServices(value) {
  return {
    type: SET_ADD_ON_SERVICES,
    payload: {
      value,
    },
  };
}
